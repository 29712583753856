import React from "react";

import { Paper, Grid } from "@mui/material";

import ProgressBar from "./components/ProgressBar";
import Status from "./components/Status";

import ErrorIcon from "@mui/icons-material/Error";

import { APIROOT } from "./api-config.js";

export default class FeatureList extends React.Component {
  state = {
    features: [],
    allFeaturesLoaded: false,
  };

  componentDidMount() {
    this.getFeatures(this.props.filter.onlyActive);
  }

  componentDidUpdate() {
    if (!this.state.allFeaturesLoaded && !this.props.filter.onlyActive) {
      this.getFeatures(this.props.filter.onlyActive);
    }
  }

  async getFeatures(active) {
    const response = await fetch(
      APIROOT + "/features?filter=" + (active ? "active" : ""),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.jwt,
        },
      }
    );

    if (response.status !== 200) {
      return [];
    }

    const data = await response.json();
    this.setState({
      features: data,
      allFeaturesLoaded: !active,
    });
  }

  filterFeatures(features) {
    const filterStatus = this.props.filter.status;

    // ofiltrovanie podla issue key parametra
    if (
      this.props.filter.issuesString &&
      this.props.filter.issuesString !== ""
    ) {
      // Split the issuesString into an array of issue keys
      const filterIssuesArray = this.props.filter.issuesString
        .split(" ")
        .map((issue) => issue.toLowerCase());

      features = features.filter(function (d) {
        // Check if the key of the object is in the filterIssuesArray
        return filterIssuesArray.includes(d.key.toLowerCase());
      });
    }

    // ofiltrovanie podla label parametra
    if (this.props.filter.label && this.props.filter.label !== "") {
      const filterLabel = this.props.filter.label;
      features = features.filter(function (d) {
        return (
          (d.requirement.labels &&
            d.requirement.labels.some((r) => r.startsWith(filterLabel))) ||
          (d.epic.labels &&
            d.epic.labels.some((r) => r.startsWith(filterLabel)))
        );
      });
    }

    // ofiltrovanie podla version parametra
    if (this.props.filter.version && this.props.filter.version !== "") {
      const filterVersion = this.props.filter.version;
      features = features.filter(function (d) {
        return (
          d.fixVersions.length > 0 &&
          d.fixVersions.some((r) => r.name.startsWith(filterVersion))
        );
      });
    }

    // ofiltrovanie podla satus parametra
    if (this.props.filter.status && this.props.filter.status.length !== 0) {
      features = features.filter(function (d) {
        return filterStatus.indexOf(d.status) >= 0;
      });
    }

    return features;
  }

  render() {
    const features = this.filterFeatures(this.state.features);

    return (
      <Grid container spacing={3}>
        {features.length > 0
          ? features.map((feature, key) => {
              return <Feature key={key} data={feature} />;
            })
          : null}
      </Grid>
    );
  }
}

class Feature extends React.Component {
  render() {
    const data = this.props.data;

    let bugs = {
      critical: [],
      major: [],
      minor: [],
    };
    let bugsClient = {
      critical: [],
      major: [],
      minor: [],
    };

    if (this.props.data.bugs !== null) {
      bugs.critical = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "6" && b.reporterRole !== "";
      });
      bugs.major = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "7" && b.reporterRole !== "";
      });
      bugs.minor = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "8" && b.reporterRole !== "";
      });
    }

    if (this.props.data.bugs !== null) {
      bugsClient.critical = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "6" && b.reporterRole === "";
      });
      bugsClient.major = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "7" && b.reporterRole === "";
      });
      bugsClient.minor = this.props.data.bugs.filter(function (b) {
        return b.priority.id === "8" && b.reporterRole === "";
      });
    }

    const estimatedSumAll =
      Math.round(
        (data.estimated.ANL +
          data.estimated.DEV +
          data.estimated.TST +
          data.estimated.UX +
          data.estimated.SUP) /
          36 /
          8
      ) / 100;

    const loggedBugsSumAll =
      Math.round(
        (data.bugsLogged.ANL +
          data.bugsLogged.DEV +
          data.bugsLogged.TST +
          data.bugsLogged.UX +
          data.bugsLogged.SUP) /
          36 /
          8
      ) / 100;
    const remainingSumAll =
      Math.round(
        (data.remaining.ANL +
          data.remaining.DEV +
          data.remaining.TST +
          data.remaining.UX +
          data.remaining.SUP) /
          36 /
          8
      ) / 100;
    const loggedSumAll =
      Math.round(
        (data.logged.ANL +
          data.logged.DEV +
          data.logged.TST +
          data.logged.UX +
          data.logged.SUP +
          loggedBugsSumAll) /
          36 /
          8
      ) / 100;

    let barsAll = [];
    if (loggedSumAll > 0) {
      barsAll = [
        ...barsAll,
        {
          color: "turquoise",
          value: loggedSumAll,
          label: loggedSumAll,
        },
      ];
    }

    if (loggedBugsSumAll > 0) {
      barsAll = [
        ...barsAll,
        {
          color: "red",
          value: loggedBugsSumAll,
          label: loggedBugsSumAll,
        },
      ];
    }

    if (remainingSumAll > 0) {
      barsAll = [
        ...barsAll,
        {
          color: "grey2",
          value: remainingSumAll,
          label: remainingSumAll,
        },
      ];
    }

    return (
      <Grid item>
        <Paper className="feature">
          <h2>
            {data.requirement.key !== "" ? (
              <img
                src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10500&avatarType=issuetype"
                alt="feature"
              />
            ) : (
              <img
                src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=14101&avatarType=issuetype"
                alt="improvement"
              />
            )}
            <a href={"https://jira.ims.jsft.io/browse/" + data.key}>
              {data.key}
            </a>{" "}
            {data.summary} <Status label={data.status} />
          </h2>
          <div className="sumProgress">
            <ProgressBar
              sum={estimatedSumAll}
              width="100%"
              fontSize="0.7em"
              tooltip={
                "Estimated: " +
                estimatedSumAll +
                " MD, " +
                "Logged: " +
                loggedSumAll +
                " MD, " +
                "Logged Bugs: " +
                loggedBugsSumAll +
                " MD, " +
                "Remaining: " +
                remainingSumAll +
                " MD"
              }
              bars={barsAll}
            />
            {remainingSumAll < 0 ? (
              <span style={{ color: "#B10E1E", fontSize: "0.7em" }}>
                <ErrorIcon color="inherit" fontSize="inherit" />
                {remainingSumAll}
              </span>
            ) : null}
          </div>
          <div className="roleEstimates">
            {Object.keys(data.estimated).map((key) => {
              let bars = [];
              if (data.logged[key] > 0) {
                bars = [
                  ...bars,
                  {
                    color: "buttonColour",
                    value:
                      Math.round((data.logged[key] / 3600 / 8) * 100) / 100,
                    label:
                      Math.round((data.logged[key] / 3600 / 8) * 100) / 100,
                  },
                ];
              }
              // najdenie lognuteho casu na bugy
              if (data.bugsLogged[key] > 0) {
                bars = [
                  ...bars,
                  {
                    color: "red",
                    value:
                      Math.round((data.bugsLogged[key] / 3600 / 8) * 100) / 100,
                    label:
                      Math.round((data.bugsLogged[key] / 3600 / 8) * 100) / 100,
                  },
                ];
              }

              if (data.remaining[key] > 0) {
                bars = [
                  ...bars,
                  {
                    color: "orange",
                    value:
                      Math.round((data.remaining[key] / 3600 / 8) * 100) / 100,
                    label:
                      Math.round((data.remaining[key] / 3600 / 8) * 100) / 100,
                  },
                ];
              }
              return (
                <div className="estimation" key={key}>
                  <span>{key}</span>
                  <ProgressBar
                    sum={
                      Math.round((data.estimated[key] / 3600 / 8) * 100) / 100
                    }
                    width="60%"
                    fontSize="0.7em"
                    tooltip={
                      "Estimated: " +
                      Math.round((data.estimated[key] / 3600 / 8) * 100) / 100 +
                      " MD, " +
                      "Logged: " +
                      Math.round((data.logged[key] / 3600 / 8) * 100) / 100 +
                      " MD, " +
                      "Logged Bugs: " +
                      Math.round((data.bugsLogged[key] / 3600 / 8) * 100) /
                        100 +
                      " MD, " +
                      "Remaining: " +
                      Math.round((data.remaining[key] / 3600 / 8) * 100) / 100 +
                      " MD"
                    }
                    bars={bars}
                  />
                  {data.remaining[key] < 0 ? (
                    <span style={{ color: "#B10E1E", fontSize: "0.7em" }}>
                      <ErrorIcon color="inherit" fontSize="inherit" />
                      {Math.round((data.remaining[key] / 3600 / 8) * 100) / 100}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
          <p className="requirementEpic">
            {data.requirement.key !== 0 ? (
              <>
                <img
                  src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10515&avatarType=issuetype"
                  alt="requirement"
                />{" "}
                <a
                  href={
                    "https://jira.ims.jsft.io/browse/" + data.requirement.key
                  }
                >
                  {data.requirement.key}
                </a>
              </>
            ) : null}
            <img
              src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10507&avatarType=issuetype"
              alt="epic"
            />{" "}
            <a href={"https://jira.ims.jsft.io/browse/" + data.epic.key}>
              {data.epic.key}
            </a>
            <span>
              (Contract Remaining:{" "}
              {Math.round(
                ((data.epic.contractEstimated - data.epic.estimated) /
                  3600 /
                  8) *
                  100
              ) / 100}{" "}
              MDs)
            </span>
          </p>
          {data.fixVersions !== null && data.fixVersions.length > 0 ? (
            <p className="versions">
              Release:
              {data.fixVersions.map((version, key) => {
                return (
                  <a
                    key={key}
                    href={
                      "https://jira.ims.jsft.io/projects/IMS/versions/" +
                      version.id
                    }
                    className={version.released === true ? "released" : null}
                  >
                    {version.name}
                  </a>
                );
              })}
            </p>
          ) : null}
          {(bugs.critical && bugs.critical.length > 0) ||
          (bugs.major && bugs.major.length > 0) ||
          (bugs.minor && bugs.minor.length > 0) ? (
            <p>Bugs:</p>
          ) : null}
          <Bugs data={bugs} />
          {(bugsClient.critical && bugsClient.critical.length > 0) ||
          (bugsClient.major && bugsClient.major.length > 0) ||
          (bugsClient.minor && bugsClient.minor.length > 0) ? (
            <p>Client Bugs:</p>
          ) : null}
          <Bugs data={bugsClient} />
          <p className="assignees">
            A: {data.assignees.ANL} D: {data.assignees.DEV} T:{" "}
            {data.assignees.TST}
          </p>
          <div className="labels">
            {data.requirement.labels && data.requirement.labels.length > 0
              ? data.requirement.labels.map((label, key) => {
                  return <span key={key}>{label}</span>;
                })
              : null}
            {data.epic.labels && data.epic.labels.length > 0
              ? data.epic.labels.map((label, key) => {
                  return <span key={key}>{label}</span>;
                })
              : null}
            {}
          </div>
        </Paper>
      </Grid>
    );
  }
}

class Bugs extends React.Component {
  render() {
    const bugs = this.props.data;
    return (
      <>
        {bugs.critical && bugs.critical.length > 0 ? (
          <p className="bugs">
            <img src={bugs.critical[0].priority.iconUrl} alt="Critical: " />
            {bugs.critical.map((bug, key) => {
              return (
                <a
                  key={key}
                  href={"https://jira.ims.jsft.io/browse/" + bug.key}
                  className={
                    "issueListItem" +
                    (bug.resolution.id === "2" ||
                    bug.resolution.id === "3" ||
                    bug.resolution.id === "5"
                      ? " notBug"
                      : bug.resolution.id !== "" &&
                        bug.fixVersions &&
                        bug.fixVersions.length > 0 &&
                        bug.fixVersions.filter((v) => {
                          return (
                            v.released && bug.affectsVersions.indexOf(v) === -1
                          );
                        }).length > 0
                      ? " releasedBug"
                      : bug.resolution.id !== ""
                      ? " resolvedBug"
                      : "")
                  }
                >
                  {bug.key}
                </a>
              );
            })}
          </p>
        ) : null}
        {bugs.major && bugs.major.length > 0 ? (
          <p className="bugs">
            <img src={bugs.major[0].priority.iconUrl} alt="Major: " />
            {bugs.major.map((bug, key) => {
              return (
                <a
                  key={key}
                  href={"https://jira.ims.jsft.io/browse/" + bug.key}
                  className={
                    "issueListItem" +
                    (bug.resolution.id === "2" ||
                    bug.resolution.id === "3" ||
                    bug.resolution.id === "5"
                      ? " notBug"
                      : bug.resolution.id !== "" &&
                        bug.fixVersions &&
                        bug.fixVersions.length > 0 &&
                        bug.fixVersions.filter((v) => {
                          return (
                            v.released && bug.affectsVersions.indexOf(v) === -1
                          );
                        }).length > 0
                      ? " releasedBug"
                      : bug.resolution.id !== ""
                      ? " resolvedBug"
                      : "")
                  }
                >
                  {bug.key}
                </a>
              );
            })}
          </p>
        ) : null}
        {bugs.minor && bugs.minor.length > 0 ? (
          <p className="bugs">
            <img src={bugs.minor[0].priority.iconUrl} alt="Minor: " />
            {bugs.minor.map((bug, key) => {
              return (
                <a
                  key={key}
                  href={"https://jira.ims.jsft.io/browse/" + bug.key}
                  className={
                    "issueListItem" +
                    (bug.resolution.id === "2" ||
                    bug.resolution.id === "3" ||
                    bug.resolution.id === "5"
                      ? " notBug"
                      : bug.resolution.id !== "" &&
                        bug.fixVersions &&
                        bug.fixVersions.length > 0 &&
                        bug.fixVersions.filter((v) => {
                          return (
                            v.released && bug.affectsVersions.indexOf(v) === -1
                          );
                        }).length > 0
                      ? " releasedBug"
                      : bug.resolution.id !== ""
                      ? " resolvedBug"
                      : "")
                  }
                >
                  {bug.key}
                </a>
              );
            })}
          </p>
        ) : null}
      </>
    );
  }
}
