let backendHost
let iamHost

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'http://localhost:8080';
  iamHost = 'http://localhost:8081'
} else {
  backendHost = '/api';
  iamHost = '/iam';
}

export const APIROOT = `${backendHost}`;
export const IAMROOT = `${iamHost}`;
