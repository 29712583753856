import React from 'react'


import { Paper } from '@mui/material'

import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts'

import { roleColors } from './roleColors'

export default class RoleTypeOverall extends React.Component {
  prepareData(origData) {
    let prepareData = {}
    let sum = 0

    origData.map((item, key) => {
      if (!prepareData[item.roleType]) {
        prepareData[item.roleType] = {
          sum: 0,
          name: item.roleType,
        }
      }
      prepareData[item.roleType].sum = Math.round((prepareData[item.roleType].sum + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
      sum += (item.timeSpent) / 3600 / 8
      return null
    })

    let data = []
    sum = Math.round(sum * 100) / 100

    Object.entries(prepareData).map(([k, v]) => {
      data[data.length] = {
        name: v.name,
        sum: v.sum,
        percentage: Math.round((v.sum / sum) * 10000) / 100
      }
      return null
    })

    return data
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Role type overall</h2>
        <PieChart width={400} height={400}>
          <Legend />
          <Tooltip formatter={(value, name, props) => { return value + " MDs; " + props.payload.percentage + " %" }} />
          <Pie data={data} dataKey="sum" nameKey="name" fill="#8884d8">
            {
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={roleColors[entry.name]}/>
              ))
            }
          </Pie>
        </PieChart>
      </Paper>
    )
  }
}
