import React from 'react'

import { Paper } from '@mui/material'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import './styles/people.sass'

import { APIROOT } from './api-config.js'

export default class PeoplePage extends React.Component {
  state = {
    data: [],
    estimation: "logged",
  }
  componentDidMount() {
    this.getData()
    this.months = []

    for (let date = new Date(2020, 0, 1); date <= new Date();date.setMonth(date.getMonth()+1)) {
      this.months[this.months.length] = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2)
    }

    // obratenie mesiacov, aby sme zacali aktualnym
    this.months.reverse()
  }

  async getData() {
    const response = await fetch(APIROOT + "/people", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    this.setState({
      data: await response.json()
    })

    
  }

  getMonthlySum(month) {
    let monthlyWorklogs = 0
    this.state.data.map((person, k) => {
      if (person.monthlyWorklogs && this.state.estimation === "logged") {
        const m = person.monthlyWorklogs.filter((mw, k2) => {
          return (mw.month === month)
        })
        if (m.length > 0) {
          monthlyWorklogs += m[0].timeSpent
        }
      } else if (person.monthlyEstimates && this.state.estimation === "estimated") {
        const m = person.monthlyEstimates.filter((mw, k2) => {
          return (mw.month === month)
        })
        if (m.length > 0) {
          monthlyWorklogs += m[0].timeSpent
        }
      } else if (this.state.estimation === "diff") {
        let worked = 0
        if (person.monthlyWorklogs) {
          const m = person.monthlyWorklogs.filter((mw, k2) => {
            return (mw.month === month)
          })
          if (m.length > 0) {
            worked = m[0].timeSpent
          }
        }

        let estimated = 0
        if (person.monthlyEstimates) {
          const m = person.monthlyEstimates.filter((mw, k2) => {
            return (mw.month === month)
          })
          if (m.length > 0) {
            estimated = m[0].timeSpent
          }
        }
        monthlyWorklogs += (estimated - worked)
      }
      return null
    })

    return Math.round(monthlyWorklogs / 36 / 8) / 100
  }

  estimationSwitch(e) {
    this.setState({
      estimation: e.target.value
    })
  }

  render() {
    return(
      <Paper className="people">
        <h1>People</h1>
        <Grid container
          direction="row"
          spacing={2}
          style={{marginBottom: "2em"}}
          >
          <Grid item>
            <FormControl style={{ minWidth: "10em" }}>
              <InputLabel id="select-label">Estimation switch</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                label="Estimation switch"
                value={this.state.estimation}
                onChange={this.estimationSwitch.bind(this)}
                >
                <MenuItem value="estimated">Estimated</MenuItem>
                <MenuItem value="logged">Logged</MenuItem>
                <MenuItem value="diff">Difference</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Person</TableCell>
              <TableCell>Role</TableCell>
              {(this.months) ?
                this.months.map((month, k) => {
                  return (
                    <TableCell key={k}>{month}</TableCell>
                  )
                }) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{backgroundColor: "#efefef"}}>
              <TableCell>SUM</TableCell>
              <TableCell></TableCell>
              {(this.months) ? this.months.map((month, key) => {
                const monthlySum = this.getMonthlySum(month)
                return (
                  <TableCell key={key}
                    className={
                      (monthlySum > 0 & this.state.estimation === "diff") ?
                        "plusEstimation" : (monthlySum < 0 & this.state.estimation === "diff") ? "minusEstimation": ""
                    }>
                    {monthlySum}
                  </TableCell>
                )
              }) : null}
            </TableRow>
            {this.state.data.map((person, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>{person.name}</TableCell>
                  <TableCell>{person.mainRole}</TableCell>
                  {(this.months) ? this.months.map((month, key) => {
                    let out = ""
                    if (person.monthlyWorklogs && this.state.estimation === "logged") {
                      const m = person.monthlyWorklogs.filter((mw, k2) => {
                        return (mw.month === month)
                      })
                      if (m.length > 0) {
                        out = Math.round(m[0].timeSpent / 36 / 8) / 100
                      }
                    } else if (person.monthlyEstimates && this.state.estimation === "estimated") {
                      const m = person.monthlyEstimates.filter((mw, k2) => {
                        return (mw.month === month)
                      })
                      if (m.length > 0) {
                        out = Math.round(m[0].timeSpent / 36 / 8) / 100
                      }
                    } else if (this.state.estimation === "diff") {
                      let worked = 0
                      if (person.monthlyWorklogs) {
                        const m = person.monthlyWorklogs.filter((mw, k2) => {
                          return (mw.month === month)
                        })
                        if (m.length > 0) {
                          worked = m[0].timeSpent
                        }
                      }

                      let estimated = 0
                      if (person.monthlyEstimates) {
                        const m = person.monthlyEstimates.filter((mw, k2) => {
                          return (mw.month === month)
                        })
                        if (m.length > 0) {
                          estimated = m[0].timeSpent
                        }
                      }
                      out = Math.round((estimated - worked)/ 36 / 8) / 100
                    }

                    if (out !== "") {
                      return (
                        <TableCell key={key}
                          className={
                            (out > 0 & this.state.estimation === "diff") ?
                              "plusEstimation" : (out < 0 & this.state.estimation === "diff") ? "minusEstimation": ""
                          }>{out}</TableCell>
                      )
                    } else {
                      return (
                        <TableCell key={key}></TableCell>
                      )
                    }
                  }) : null}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}
