import React from 'react'

import { Paper, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import FeatureList from './FeatureList'

import FilterStorage from './components/FilterStorage'

import './styles/cards.sass'

export default class FeaturePage extends React.Component {
  state = {
    filter: {
      issues: [],
      issuesString: "",
      label: "",
      version: "",
      status: ["Open","In Analysis","In Review","Customer Handover","Customer Review","Awaiting Implementation","Implementing","Implemented","Code Review","Ready To Test","Testing Issue","Acceptance"],
      statusString: "Open,In Analysis,In Review,Customer Handover,Customer Review,Awaiting Implementation,Implementing,Implemented,Code Review,Ready To Test,Testing Issue,Acceptance",
      onlyActive: true
    },
  }

  constructor(props) {
    super(props)
    this.filterStorage = React.createRef()
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter
    })
  }

  filterIssue(e) {
    let state = this.state

    this.filterStorage.current.cancelAllExcept()

    state.filter.issuesString = e.target.value
    this.setState(state)
  }

  filterLabel(e) {
    let state = this.state
    state.filter.label = e.target.value

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterVersion(e) {
    let state = this.state
    state.filter.version = e.target.value

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterStatus(e) {
    let state = this.state
  
    state.filter.statusString = e.target.value
    const regex = /[a-zA-Z ]+/g
    let status = e.target.value.match(regex)

    if (status === null || status.indexOf("Closed") >= 0 || status.length === 0) {
      state.filter.onlyActive = false
    }

    state.filter.status = status

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  render() {
    return (
      <Paper className="mainList featureList">
        <h1>Features</h1>
        <Grid container
          direction="row"
          spacing={2}
          >
          <Grid item>
            <TextField id="issue-key"
              label="Issue keys"
              onChange={this.filterIssue.bind(this)}
              value={this.state.filter.issuesString}
              />
          </Grid>
          <Grid item>
            <TextField id="label"
              label="Label"
              onChange={this.filterLabel.bind(this)}
              value={this.state.filter.label}
              />
          </Grid>
          <Grid item>
            <TextField id="version"
              label="Version"
              onChange={this.filterVersion.bind(this)}
              value={this.state.filter.version}
              />
          </Grid>
          <Grid item style={{width: '15em'}}>
            <FormControl fullWidth>
              <InputLabel id="select-label-status">Status</InputLabel>
              <Select
                labelId="select-label-status"
                id="select-status"
                label="Status"
                value={this.state.filter.statusString}
                onChange={this.filterStatus.bind(this)}
                >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem
                  value="Open,In Analysis,In Review,Customer Handover,Customer Review,Awaiting Implementation,Implementing,Implemented,Code Review,Ready To Test,Testing Issue,Acceptance"
                  >
                  Active
                </MenuItem>
                <MenuItem value="Open,In Analysis,In Review,Customer Handover">Analysis</MenuItem>
                <MenuItem value="Customer Review">Customer review</MenuItem>
                <MenuItem value="Awaiting Implementation,Implementing,Implemented,Code Review">Implementation</MenuItem>
                <MenuItem value="Ready To Test,Testing Issue">Testing</MenuItem>
                <MenuItem value="Acceptance">Acceptance</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FilterStorage
          ref={this.filterStorage}
          context="features"
          loadFilter={this.handleLoadFilter.bind(this)}
          currentFilter={this.state.filter}
          defaultFilter={{
            issues: [],
            issuesString: "",
            label: "",
            version: "",
            status: ["Open","In Analysis","In Review","Customer Handover","Customer Review","Awaiting Implementation","Implementing","Implemented","Code Review","Ready To Test","Testing Issue","Acceptance"],
            statusString: "Open,In Analysis,In Review,Customer Handover,Customer Review,Awaiting Implementation,Implementing,Implemented,Code Review,Ready To Test,Testing Issue,Acceptance",
          }}
          />
        <FeatureList
          filter={this.state.filter}
          jwt={this.props.jwt}
          />
      </Paper>
    )
  }
}
