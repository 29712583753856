import React from "react";

import { Paper, Grid } from "@mui/material";

import ProgressBar from "./components/ProgressBar";
import Status from "./components/Status";

import ErrorIcon from "@mui/icons-material/Error";

import "./styles/requirementList.sass";

import { APIROOT } from "./api-config.js";

export default class FeatureList extends React.Component {
  state = {
    requirements: [],
  };

  componentDidMount() {
    this.getRequirements();
  }

  async getRequirements() {
    const response = await fetch(APIROOT + "/requirements", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.jwt,
      },
    });

    if (response.status !== 200) {
      return [];
    }

    const data = await response.json();

    this.setState({
      requirements: data,
    });
  }

  filter(requirements) {
    // ofiltrovanie podla issue key parametra
    if (this.props.filter.issuesString && this.props.filter.issuesString !== "") {
      const filterIssues = this.props.filter.issuesString;
      requirements = requirements.filter(function (d) {
        return d.keyAggregate.some(
          (r) => r?.toLowerCase().includes(filterIssues.toLowerCase())
        );
        
      });
    }

    // ofiltrovanie podla label parametra
    if (this.props.filter.label && this.props.filter.label !== "") {
      const filterLabel = this.props.filter.label;
      requirements = requirements.filter(function (req) {
        return (
          req.labels.some((r) => r.startsWith(filterLabel)) ||
          req.epic.labels.some((r) => r.startsWith(filterLabel))
        );
      });
    }

    // ofiltrovanie podla satus parametra
    if (this.props.filter.status && this.props.filter.status.length !== 0) {
      const filterStatus = this.props.filter.status;
      requirements = requirements.filter(function (d) {
        return filterStatus.indexOf(d.status) >= 0;
      });
    }

    return requirements;
  }

  render() {
    //
    const requirements = this.filter(this.state.requirements);
    return (
      <Grid container spacing={3}>
        {requirements.length > 0
          ? requirements.map((requirement, key) => {
              return <Requirement key={key} data={requirement} />;
            })
          : null}
      </Grid>
    );
  }
}

class Requirement extends React.Component {
  render() {
    const data = this.props.data;

    let bars = [];
    const loggedSum =
      Math.round(data.preAnalysisEstimation.logged / 36 / 8) / 100;
    const remainingSum =
      Math.round(data.preAnalysisEstimation.remaining / 36 / 8) / 100;
    const estimatedSum =
      Math.round(data.preAnalysisEstimation.estimated / 36 / 8) / 100;

    if (loggedSum > 0) {
      bars = [
        ...bars,
        {
          color: "turquoise",
          value: loggedSum,
          label: loggedSum,
        },
      ];
    }
    if (remainingSum > 0) {
      bars = [
        ...bars,
        {
          color: "grey2",
          value: remainingSum,
          label: remainingSum,
        },
      ];
    }

    let fBars = [];
    const loggedFeaturesSum =
      Math.round(data.featuresEstimation.logged / 36 / 8) / 100;
    const remainingFeaturesSum =
      Math.round(data.featuresEstimation.remaining / 36 / 8) / 100;
    const estimatedFeaturesSum =
      Math.round(data.featuresEstimation.estimated / 36 / 8) / 100;
    if (loggedFeaturesSum > 0) {
      fBars = [
        ...fBars,
        {
          color: "green",
          value: loggedFeaturesSum,
          label: loggedFeaturesSum,
        },
      ];
    }
    if (remainingFeaturesSum > 0) {
      fBars = [
        ...fBars,
        {
          color: "orange",
          value: remainingFeaturesSum,
          label: remainingFeaturesSum,
        },
      ];
    }
    return (
      <Grid item>
        <Paper className="requirement">
          <h2>
            <a href={"https://jira.ims.jsft.io/browse/" + data.key}>
              {data.key}
            </a>{" "}
            {data.summary} <Status label={data.status} />
          </h2>
          <div className="">
            <ProgressBar
              sum={estimatedSum}
              width="100%"
              fontSize="0.7em"
              tooltip={
                "Estimated: " +
                estimatedSum +
                " MD, " +
                "Logged: " +
                loggedSum +
                " MD, " +
                "Remaining: " +
                remainingSum +
                " MD"
              }
              bars={bars}
            />
            {remainingSum < 0 ? (
              <span style={{ color: "#B10E1E", fontSize: "0.7em" }}>
                <ErrorIcon color="inherit" fontSize="inherit" />
                {remainingSum}
              </span>
            ) : null}
          </div>
          <div className="sumProgress">
            <ProgressBar
              sum={estimatedFeaturesSum}
              width="100%"
              fontSize="0.7em"
              tooltip={
                "Estimated: " +
                estimatedFeaturesSum +
                " MD, " +
                "Logged: " +
                loggedFeaturesSum +
                " MD, " +
                "Remaining: " +
                remainingFeaturesSum +
                " MD"
              }
              bars={fBars}
            />
          </div>
          <div className="labels">
            {data.labels && data.labels.length > 0
              ? data.labels.map((label, key) => {
                  return <span key={key}>{label}</span>;
                })
              : null}
            {data.epic.labels && data.epic.labels.length > 0
              ? data.epic.labels.map((label, key) => {
                  return <span key={key}>{label}</span>;
                })
              : null}
          </div>
          <p className="requirementEpic">
            <img
              src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10507&avatarType=issuetype"
              alt="epic"
            />{" "}
            <a href={"https://jira.ims.jsft.io/browse/" + data.epicKey}>
              {data.epicKey}
            </a>
            <span>
              (Contract Remaining:{" "}
              {Math.round(
                (data.epic.contractEstimated - data.epic.estimated) / 36 / 8
              ) / 100}{" "}
              MDs)
            </span>
          </p>
          {data.features && data.features.length > 0 ? (
            <div className="features">
              {data.features.map((f, key) => {
                const loggedSum =
                  Math.round(
                    (f.logged.ANL +
                      f.logged.DEV +
                      f.logged.TST +
                      f.logged.UX +
                      f.logged.SUP) /
                      36 /
                      8
                  ) / 100;
                const loggedBugsSum =
                  Math.round(
                    (f.bugsLogged.ANL +
                      f.bugsLogged.DEV +
                      f.bugsLogged.TST +
                      f.bugsLogged.UX +
                      f.bugsLogged.SUP) /
                      36 /
                      8
                  ) / 100;
                const remainingSum =
                  Math.round(
                    (f.remaining.ANL +
                      f.remaining.DEV +
                      f.remaining.TST +
                      f.remaining.UX +
                      f.remaining.SUP) /
                      36 /
                      8
                  ) / 100;
                const estimatedSum =
                  Math.round(
                    (f.estimated.ANL +
                      f.estimated.DEV +
                      f.estimated.TST +
                      f.estimated.UX +
                      f.estimated.SUP) /
                      36 /
                      8
                  ) / 100;

                let bars = [];
                if (loggedSum > 0) {
                  bars = [
                    ...bars,
                    {
                      color: "green",
                      value: loggedSum,
                      label: loggedSum,
                    },
                  ];
                }
                if (loggedBugsSum > 0) {
                  bars = [
                    ...bars,
                    {
                      color: "red",
                      value: loggedBugsSum,
                      label: loggedBugsSum,
                    },
                  ];
                }
                if (remainingSum > 0) {
                  bars = [
                    ...bars,
                    {
                      color: "orange",
                      value: remainingSum,
                      label: remainingSum,
                    },
                  ];
                }
                return (
                  <div key={key}>
                    <img
                      src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10500&avatarType=issuetype"
                      alt="feature"
                      className="jiraIcon"
                    />
                    <a href={"https://jira.ims.jsft.io/browse/" + f.key}>
                      {f.key}
                    </a>{" "}
                    {f.summary} <Status label={f.status} />
                    <ProgressBar
                      sum={estimatedSum}
                      width="100%"
                      fontSize="0.7em"
                      tooltip={
                        "Estimated: " +
                        estimatedSum +
                        " MD, " +
                        "Logged: " +
                        loggedSum +
                        " MD, " +
                        "Bugs logged: " +
                        loggedBugsSum +
                        " MD, " +
                        "Remaining: " +
                        remainingSum +
                        " MD"
                      }
                      bars={bars}
                    />
                    {remainingSum < 0 ? (
                      <span style={{ color: "#B10E1E", fontSize: "0.7em" }}>
                        <ErrorIcon color="inherit" fontSize="inherit" />
                        {remainingSum}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}
        </Paper>
      </Grid>
    );
  }
}
