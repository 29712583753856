import React from 'react'

import '../styles/status.sass'

import Tooltip from '@mui/material/Tooltip'

const phase = {
  Open: "",
  "Requirement Definition": "preanalysis",
  "Assign Analyst": "preanalysis",
  "In Analysis": "preanalysis",
  "Analysis Completed": "preanalysis",
  "Awaiting Approval": "preanalysis",
  "Create Features": "features",
  "Features In Progress": "features",
  "Awaiting Final Approval": "acceptance",
  "Approved": "closed",

  // Features
  "In Review": "preanalysis",
  "Customer Handover": "analysis",
  "Customer Review": "analysis",
  "Awaiting Implementation": "development",
  "Implementing": "development",
  "Implemented": "development",
  "Code Review": "development",
  "Ready To Test": "testing",
  "Testing Issue": "testing",
  "Acceptance": "acceptance",
  "Closed": "closed",
}


export default class Status extends React.Component {
  render() {
    return (
      <Tooltip title={this.props.label}>
        <div
          className={"status " + phase[this.props.label]}>
          {this.props.label}
        </div>
      </Tooltip>
    )
  }
}
