import React from 'react'


import { Paper } from '@mui/material'

import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Label } from 'recharts'

import { roleColors } from './roleColors'

export default class RoleType extends React.Component {

  addToWeek(role, timeSpent, week, data) {
    const index = data.findIndex((element) => {
      return element.week === week
    })
    data[index][role] = Math.round((data[index][role] + Math.round((timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    return data
  }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  prepareData(origData) {
    let data = []

    origData.map((item, key) => {
      if (data.filter(function (d) {
        return d.week === item.week
      }).length === 0) {
        const weekSplit = item.week.split("-")
        const date = this.getDateOfISOWeek(weekSplit[1], weekSplit[0])
        data = [...data, {
          week: item.week,
          weekDate: date.getFullYear() + "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("0" + date.getDate()).slice(-2),
          'ANL': 0,
          'DEV': 0,
          'TST': 0,
          'UX': 0,
          'SUP': 0,
        }]
      }

      data = this.addToWeek(item.roleType, item.timeSpent, item.week, data)
      return null
    })
    return data
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Role type</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={1000} height={400} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}  >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="weekDate">
              <Label value="weeks (monday date)" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              label={{ value: 'MDs', angle: -90, position: 'insideLeft' }} />
            <Legend verticalAlign="top" />
            <Tooltip formatter={(value, name, props) => { return value + " MDs" }} />
            <Bar dataKey="ANL" fill={roleColors.ANL} />
            <Bar dataKey="DEV" fill={roleColors.DEV} />
            <Bar dataKey="TST" fill={roleColors.TST} />
            <Bar dataKey="UX" fill={roleColors.UX} />
            <Bar dataKey="SUP" fill={roleColors.SUP} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    )
  }
}
