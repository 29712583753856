import React from 'react'

import { Paper, Grid, Box, Stack, Button, Switch } from '@mui/material'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Select from '@mui/material/Select'

import HomeIcon from '@mui/icons-material/Home'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { APIROOT } from './api-config.js'

export default class PoCPage extends React.Component {
  rootNodeTypes = ["programSK","program"]
  state = {
    node: {},
    pathData: [],
    updateAllowedSubtypes: [],
    currentLevel: null,
    loading: false,
    insertName: "",
    insertType: "",
    editMode: false,
  }

  componentDidMount() {
    this.getLevelData() 
  }

  async getLevelData() {
    const response = await fetch(APIROOT + "/poc" + ((this.state.currentLevel) ? "?id=" + this.state.currentLevel : ""), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    const data = await response.json()

    this.setState({
      node: data,
      updateAllowedSubtypes: data.allowedSubtypes||[]
    })
  }

  async changeLevel(item) {
    const path = this.state.pathData
    let pd = []
    let currentLevel = null

    if (item !== null) {
      

      const idx = path.findIndex((e) => { 
        return e.id === item.id
      })
      if (idx === -1) {
        pd = [...path, item]
        currentLevel = item.id
      } else {
        pd = path.splice(0, (idx + 1))
        currentLevel = item.id
      }
    }

    await this.setState({
      pathData: pd,
      currentLevel: currentLevel,
      insertName: "",
      insertType: "",
      editMode: false
    })
    this.getLevelData()
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    })
  }

  handleArrayChange(event, array, name) {
    this.setState({
      ...this.state,
      [name]: array,
    })
  }
  
  handleChangeChecked(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    })
  }

  async deleteNode(id) {
    await fetch((APIROOT + "/poc?id=" + id), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      },
    })
    this.getLevelData()
  }

  async handleUpdateSubtypesSubmit(e) {
    e.preventDefault()

    await fetch((APIROOT + `/poc/types?type=${encodeURIComponent(this.state.node.type)}`), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      },
      body: JSON.stringify(this.state.updateAllowedSubtypes)
    })
    this.getLevelData()
  }

  async handleInsertSubmit(event) {
    event.preventDefault()
    if (this.state.insertName === "" || this.state.insertType === "") {
      return
    }
    const insertObj = {
      name: this.state.insertName,
      type: this.state.insertType
    }

    this.setState({
      insertName: "",
      insertType: "",
      editMode: false
    })

    await fetch(APIROOT + "/poc" + ((this.state.currentLevel) ? "?id=" + this.state.currentLevel : ""), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      },
      body: JSON.stringify(insertObj)
    })
    this.getLevelData()
  }

  render() {
    const level = this.state.node.subNodes||[]
    const path = this.state.pathData

    const allowedSubtypes = this.state.node.allowedSubtypes||[]

    return(
      <Paper className="people">
        <h1>PoC</h1>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Box sx={{ width: '100%' }}>
              <Breadcrumbs maxItems={3} aria-label="breadcrumb">
              {(path.length > 0) ? (
                <Link 
                  sx={{ display: 'flex', alignItems: 'center' }}
                  underline="hover" color="inherit" href="#"
                  onClick={() => this.changeLevel(null)}>
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                </Link>
                ) : (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />&nbsp;
                  </Typography>
                )}
                {path.map((i, k) => {
                  if (k === (path.length - 1)) {
                    return (
                      <Typography color="text.primary" key={i.id}>
                        {i.name}
                      </Typography>
                    )
                  }
                  return (
                    <Link underline="hover" color="inherit" href="#" key={i.id}
                      onClick={() => this.changeLevel(i)}>
                      {i.name}
                    </Link>
                  )
                })}
              </Breadcrumbs>
            </Box>
            <Box sx={{ width: '100%', maxWidth: 360 }}>
              <List>
                {level.map(item => {
                  return (
                    <ListItem
                      key={item.id}
                      disablePadding
                      secondaryAction={(this.state.editMode) ? (
                        <IconButton
                          aria-label="comment"
                          onClick={() => this.deleteNode(item.id)}
                          >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      ) : null}>
                      <ListItemButton onClick={() => this.changeLevel(item)}>
                        <ListItemText primary={item.name} secondary={item.type} 
                          />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2} direction="column">
              <Box>
                <p><strong>Insert node</strong></p>
                <form onSubmit={this.handleInsertSubmit.bind(this)}>
                <Stack spacing={2} alignItems="center" direction="row">
                    <TextField
                      label="Name"
                      value={this.state.insertName}
                      name="insertName"
                      onChange={this.handleChange.bind(this)} />
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel id="select-label">Type</InputLabel>
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={this.state.insertType}
                        name="insertType"
                        label="Type"
                        onChange={this.handleChange.bind(this)}
                      >
                        {allowedSubtypes.map((item, k) => (
                          <MenuItem value={item} key={k}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained">
                        Insert
                    </Button>
                </Stack>
                </form>
              </Box>
              <FormGroup>
                <FormControlLabel control={
                  <Switch 
                    checked={this.state.editMode}
                    onChange={this.handleChangeChecked.bind(this)}
                    name="editMode"
                    />} label="Edit mode" />
              </FormGroup>
              <form onSubmit={this.handleUpdateSubtypesSubmit.bind(this)}>
                <Stack spacing={2} alignItems="center" direction="row">
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    value={this.state.updateAllowedSubtypes}
                    onChange={(a, b) => this.handleArrayChange(a, b, "updateAllowedSubtypes")}
                    freeSolo
                    sx={{ minWidth: 600 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Possible subtypes"
                        placeholder=""
                      />
                      )}
                    />
                  <Button
                      type="submit"
                      variant="outlined">
                        Update
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
