import React from "react"

import { Button, TextField, Paper } from '@mui/material'

import { APIROOT } from './api-config.js'

export default class ScryptPage extends React.Component {
  state = {
    password: "",
    passwordHash: null
  }

  async handleFormSubmit(e) {
    e.preventDefault()
    const response = await fetch(APIROOT + "/utils/scrypt?password=" + encodeURIComponent(this.state.password), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    const data = await response.json()
    this.setState({
      passwordHash: data.passwordHash
    })
  }

  passwordChange(e) {
    let state = this.state
    state.password = e.target.value
    this.setState(state)
  }

  render() {
    return (
      <Paper className="scrypt">
        <h1>Scrypt password hash generator</h1>
        <form onSubmit={this.handleFormSubmit.bind(this)}>
          <div>
            <TextField id="issue-key"
              label="Password"
              onChange={this.passwordChange.bind(this)}
              value={this.state.password}
              />
          </div>
          <div>
            <Button type="submit" variant="contained" disabled={(this.state.password === "")} color="primary">
              Generate
            </Button>
          </div>
        </form>
        {(this.state.passwordHash) ? (
          <>
            <p>Your password hash:</p>
            <pre>{this.state.passwordHash}</pre>
          </>
        ) : null}
      </Paper>
    )
  }
}