import React from 'react'


import { Paper } from '@mui/material'

import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts'

import { roleColors } from '../roleColors'

export default class RoleType extends React.Component {

  prepareData(origData) {
    let data = [
      {
        'sum' : 0,
        'name' : 'ANL',
        'color' : roleColors.ANL
      },
      {
        'sum' : 0,
        'name' : 'DEV',
        'color' : roleColors.DEV
      },
      {
        'sum' : 0,
        'name' : 'TST',
        'color' : roleColors.TST
      },
      {
        'sum' : 0,
        'name' : 'UX',
        'color' : roleColors.UX
      },
      {
        'sum' : 0,
        'name' : 'SUP',
        'color' : roleColors.SUP
      }
    ]

    origData.map((item, key) => {
      data[0].sum = (item.remaining.ANL > 0) ? (data[0].sum + item.remaining.ANL) : data[0].sum
      data[1].sum = (item.remaining.DEV > 0) ? (data[1].sum + item.remaining.DEV) : data[1].sum
      data[2].sum = (item.remaining.TST > 0) ? (data[2].sum + item.remaining.TST) : data[2].sum
      data[3].sum = (item.remaining.UX > 0) ? (data[3].sum + item.remaining.UX) : data[3].sum
      data[4].sum = (item.remaining.SUP > 0) ? (data[4].sum + item.remaining.SUP) : data[4].sum

      return null
    })

    data[0].sum = Math.round(data[0].sum / 36 / 8) / 100
    data[1].sum = Math.round(data[1].sum / 36 / 8) / 100
    data[2].sum = Math.round(data[2].sum / 36 / 8) / 100
    data[3].sum = Math.round(data[3].sum / 36 / 8) / 100
    data[4].sum = Math.round(data[4].sum / 36 / 8) / 100

    return data
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Role type</h2>
        <p>SUM: {(Math.round((data[0].sum + data[1].sum + data[2].sum + data[3].sum + data[4].sum) * 100) / 100)} MDs</p>
        <PieChart width={400} height={400}>
          <Legend />
          <Tooltip formatter={(value, name, props) => { return value + " MDs" }} />
          <Pie data={data} dataKey="sum" nameKey="name" fill="#8884d8">
            {
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}/>
              ))
            }
          </Pie>
        </PieChart>
      </Paper>
    )
  }
}
