import React from 'react'

import { Paper, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material'

import FilterStorage from './components/FilterStorage'

import Version from './Version'
import VersionGraph from './graphs/VersionGraph'
import VersionGraphSpent from './graphs/VersionGraphSpent'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import './styles/versions.sass'

import { APIROOT } from './api-config.js'

import moment from "moment"
moment.updateLocale("en", {
  week: {
    dow: 1
  }
})

export default class VersionPage extends React.Component {
  state = {
    data: [],
    filter: {
      version: "",
      released: true,
      majorVersion: false,
      from: new Date()
    }
  }

  constructor(props) {
    super(props)
    this.filterStorage = React.createRef()

    const d = this.state.filter.from
    d.setMonth(d.getMonth() - 3)
    d.setDate(1)

    const day = d.getDay() || 7
    if( day !== 1 )
      d.setHours(-24 * (day - 1))
  }

  componentDidMount() {
    this.getData()
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter
    })
  }

  getCurrentFilter() {
    return this.state.filter
  }

  async getData() {
    const from = (this.state.filter.from).getFullYear()+'-'+
      ('0' + ((this.state.filter.from).getMonth()+1)).slice(-2)+
      '-'+
      ('0' + (this.state.filter.from).getDate()).slice(-2)

    const response = await fetch(APIROOT + "/versions?from=" + from, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    this.setState({
      data: await response.json()
    })
  }

  filterMajorVersion(e) {
    let state = this.state
    state.filter.majorVersion = e.target.checked

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterVersion(e) {
    let state = this.state
    state.filter.version = e.target.value

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterReleased(e) {
    let state = this.state
    state.filter.released = e.target.checked

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterFromDate(e) {
    if (e.toDate() === "Invalid Date") {
      return
    }
    let state = this.state
    state.filter.from = e.toDate()

    this.filterStorage.current.cancelAllExcept()
    this.setState(state)
    this.getData()
  }

  compareBugPriority(a, b) {
    if (a.priority.id < b.priority.id) {
      return -1
    }
    if (a.priority.id > b.priority.id) {
      return 1
    }
    if (a.key < b.key) {
      return -1
    }
    if (a.key > b.key) {
      return 1
    }
    return 0
  }

  filterData(data) {
    // ofiltrovanie podla version parametra
    if (this.state.filter.version && this.state.filter.version !== "") {
      const filterVersion = this.state.filter.version
      data = data.filter(function (d) {
        return d.name.startsWith(filterVersion)
      })
    }

    // ofiltrovanie iba releasenutych verzii
    if (this.state.filter.released) {
      data = data.filter(function (d) {
        return d.released
      })
    }

    // zgrupenie iba na major verzie
    if (this.state.filter.majorVersion) {
      let grouppedData = []
      let grouppedDataIds = []
      data.map((version, i) => {
        const majorVersionRegex = /^([1-9]\d*\.[1-9]\d*)/g;
        const rcRegex = /-RC\d+/g
        const majorVersion = version.name.match(majorVersionRegex)[0]
        const versionIdx = grouppedDataIds.indexOf(majorVersion)

        if (versionIdx === -1) {
          grouppedDataIds[grouppedDataIds.length] = majorVersion
          grouppedData[grouppedData.length] = {
            id: grouppedData.length + 1,
            name: majorVersion,
            released: (rcRegex.test(version.name)),
            bugs: version.bugs,
            reopenedBugs: null,
            featuresFixed: version.featuresFixed,
            bugsFixed: version.bugsFixed,
          }
        } else {
          let grouppedBugs = (grouppedData[versionIdx].bugs||[]).concat(version.bugs||[])
          .filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.key === item.key
            )))

          grouppedBugs.sort(this.compareBugPriority)

          grouppedData[versionIdx] = {
            id: grouppedData[versionIdx].id,
            name: grouppedData[versionIdx].name,
            released: ((grouppedData[versionIdx]) ? true : rcRegex.test(version.name)),
            bugs: grouppedBugs,
            reopenedBugs: null,
            featuresFixed: (grouppedData[versionIdx].featuresFixed||[]).concat(version.featuresFixed||[])
              .filter((item, index, self) =>
                index === self.findIndex((t) => (
                  t.key === item.key
                ))),
            bugsFixed: (grouppedData[versionIdx].bugsFixed||[]).concat(version.bugsFixed||[])
              .filter((item, index, self) =>
                index === self.findIndex((t) => (
                  t.key === item.key
                ))),
          }
        }
        return null
      })
      
      data = grouppedData
    }

    return data
  }

  render() {
    const data = this.filterData(this.state.data)
    return (
      <Paper className="mainList">
        <h1>Versions</h1>
        <Grid container
          direction="row"
          spacing={2}
          >
          <Grid item>
            <TextField id="version"
              label="Version"
              onChange={this.filterVersion.bind(this)}
              value={this.state.filter.version}
              />
          </Grid>
          <Grid
            item>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.filter.released}
                    onChange={this.filterReleased.bind(this)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Released only"
              />
          </Grid>
          <Grid
            item>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.filter.majorVersion}
                    onChange={this.filterMajorVersion.bind(this)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Major version groupping"
              />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item>
              <DesktopDatePicker
                label="Version start from"
                inputFormat="YYYY-MM-DD"
                value={this.state.filter.from}
                onChange={this.filterFromDate.bind(this)}
                renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
          </LocalizationProvider>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-from"
              label="Version start from"
              autoOk={true}
              value={this.state.filter.from}
              onChange={this.filterFromDate.bind(this)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              />
          </Grid>
          </MuiPickersUtilsProvider> */}
        </Grid>
        <FilterStorage
          ref={this.filterStorage}
          context="version"
          loadFilter={this.handleLoadFilter.bind(this)}
          currentFilter={this.state.filter}
          defaultFilter={{
            version: "",
            released: true,
            majorVersion: false,
            from: null
          }}
          />
        <VersionGraph
          data={data}
          />
        <VersionGraphSpent
          data={data}
          />
        {data.map((item, key) => {
          return (
            <Version
              key={key}
              data={item}
              />
          )
        })}
        <h2>Explanation</h2>
        <h3>Bugs</h3>
        <p>
          Bugs are either in group resolved - those are the bugs that came with
          release notes of the version. Another case is with those named
          "Introduced". Those are bugs that have been identified while testing
          the version (assigned to version via "Affects version" field in Jira).
        </p>
        <p>
          Introduced bugs aro of two categories:
        </p>
        <ul>
          <li><strong>New</strong> - new bugs that have been identified while testing the version,</li>
          <li><strong>Reopened</strong> - bugs that should have been resolved with the version but instead they were reopened when testing the version.</li>
        </ul>
        <h3>Bugs colorcoding and graphs</h3>
        <p>
          If an issue key of a bug is light grey it means the bug is not
          really a bug because it has one of the following resolutions:
          "Won't fix", "Duplicate" or "Cannot Reproduce".
        </p>
        <p>
          If an issue key of a bug is of a dark grey color it means the bug has
          been released (has resolution and has a fix version in Jira that had
          been released).
        </p>
        <p>
          If an issue key of a bug is of a black color it means the bug has
          been resolved (has resolution in Jira) other than one of the
          resolutions mentioned above and has not been assigned a fix version or
          the fix version has not been released or the bug was reopened (not
          finally fixed).
        </p>
        <p>
          All of the graphs only include bugs which have not been resolved
          with one of the resolutions - "Won't fix", "Duplicate" or
          "Cannot Reproduce".
        </p>
      </Paper>
    )
  }
}
