import React from "react";

import {
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import RoleType from "./graphs/RoleType";
import RoleTypeOverall from "./graphs/RoleTypeOverall";
import ActivityType from "./graphs/ActivityType";
import ContractOverall from "./graphs/ContractOverall";
import FunctionalNonfunctional from "./graphs/FunctionalNonfunctional";

import FilterStorage from "./components/FilterStorage";

import "./styles/graph.sass";

import { APIROOT } from "./api-config.js";

import moment from "moment";
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

export default class GraphPage extends React.Component {
  state = {
    data: [],
    from: new Date(),
    until: new Date(),
    filter: {
      issues: [],
      issuesString: "",
      issueType: "",
      roleType: "",
      invoiceLabel: "",
      // all|only|none
      zv: "all",
    },
  };

  constructor(props) {
    super(props);
    this.filterStorage = React.createRef();
    const d = this.state.from;
    d.setMonth(d.getMonth() - 3);
    d.setDate(1);

    const day = d.getDay() || 7;
    if (day !== 1) d.setHours(-24 * (day - 1));
  }

  componentDidMount() {
    this.getData();
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter,
    });
  }

  async getData() {
    const from =
      this.state.from.getFullYear() +
      "-" +
      ("0" + (this.state.from.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + this.state.from.getDate()).slice(-2);
    const until =
      this.state.until.getFullYear() +
      "-" +
      ("0" + (this.state.until.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + this.state.until.getDate()).slice(-2);
    const response = await fetch(
      APIROOT + "/weekData?from=" + from + "&until=" + until,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.jwt,
        },
      }
    );

    if (response.status !== 200) {
      return [];
    }

    this.setState({
      data: await response.json(),
    });
  }

  filterIssue(e) {
    const regex = /[1-9][0-9]*/g;
    const issueKeys = e.target.value.match(regex);
    let state = this.state;

    this.filterStorage.current.cancelAllExcept();

    state.filter.issues = issueKeys;
    state.filter.issuesString = e.target.value;
    this.setState(state);
  }

  filterRoleType(e) {
    let state = this.state;
    state.filter.roleType = e.target.value;

    this.filterStorage.current.cancelAllExcept();
    this.setState(state);
  }

  filterIssueType(e) {
    let state = this.state;
    state.filter.issueType = e.target.value;

    this.filterStorage.current.cancelAllExcept();
    this.setState(state);
  }

  filterInvoiceLabel(e) {
    let state = this.state;
    state.filter.invoiceLabel = e.target.value;

    this.filterStorage.current.cancelAllExcept();
    this.setState(state);
  }

  filterFromDate(e) {
    if (isNaN(Date.parse(e.toDate()))) {
      return;
    }

    let state = this.state;
    state.from = e.toDate();

    this.filterStorage.current.cancelAllExcept();
    this.setState(state);
    this.getData();
  }
  filterUntilDate(e) {
    if (isNaN(Date.parse(e.toDate()))) {
      return;
    }

    let state = this.state;
    state.until = e.toDate();

    this.filterStorage.current.cancelAllExcept();
    this.setState(state);
    this.getData();
  }
  filterZV(e) {
    let state = this.state;

    switch (state.filter.zv) {
      case "all":
        state.filter.zv = "only";
        break;
      case "only":
        state.filter.zv = "none";
        break;
      case "none":
        state.filter.zv = "all";
        break;
      default:
    }
    this.setState(state);
  }

  filterData(data) {
    // ofiltrovanie podla issue key parametra
    if (
      this.state.filter.issuesString &&
      this.state.filter.issuesString !== ""
    ) {
      // Split the issuesString into an array of issue keys
      const filterIssuesArray = this.state.filter.issuesString
        .split(" ")
        .map((issue) => issue.toLowerCase());

      data = data.filter(function (d) {
        // Check if any of the keys in keyaggregate are in the filterIssuesArray
        return d.keyAggregate.some((key) =>
          filterIssuesArray.includes(key.toLowerCase())
        );
      });
    }

    if (this.state.filter.roleType && this.state.filter.roleType !== "") {
      const filterRoleType = this.state.filter.roleType;
      data = data.filter(function (d) {
        return d.roleType === filterRoleType;
      });
    }

    if (this.state.filter.issueType && this.state.filter.issueType !== "") {
      const filterIssueType = this.state.filter.issueType;
      data = data.filter(function (d) {
        if (d.requirementKey && filterIssueType === "Requirement") {
          return true;
        }
        if (d.featureKey && filterIssueType === "Feature") {
          return true;
        }
        if (d.improvementKey && filterIssueType === "Improvement") {
          return true;
        }
        if (d.taskKey && filterIssueType === "Task") {
          return true;
        }
        if (d.bugKey && filterIssueType === "Bug") {
          return true;
        }
        if (d.faultKey && filterIssueType === "Fault") {
          return true;
        }
        if (d.serviceKey && filterIssueType === "Service") {
          return true;
        }
        return false;
      });
      console.log(data);
    }

    if (
      this.state.filter.invoiceLabel &&
      this.state.filter.invoiceLabel !== ""
    ) {
      const filterInvoiceLabel = this.state.filter.invoiceLabel;
      data = data.filter(function (d) {
        return d.invoiceLabel === parseInt(filterInvoiceLabel);
      });
    }

    if (this.state.filter.zv && this.state.filter.zv !== "all") {
      const filterZV = this.state.filter.zv;
      data = data.filter(function (d) {
        if (filterZV === "only") {
          return d.ZV;
        }
        if (filterZV === "none") {
          return !d.ZV;
        }
        return false;
      });
    }

    return data;
  }

  getFilteredIssues(data) {
    let issues = [];
    let ids = [];

    data.forEach((val, i) => {
      let [key, type] = this.getIssueKeyType(val);
      let item = {
        key: key,
        issueType: type,
      };
      if (ids.indexOf(item.key) === -1) {
        issues.push(item);
        ids.push(item.key);
      }
    });
    return issues;
  }

  getIssueKeyType(issue) {
    if (issue.requirementKey) {
      return [issue.requirementKey, "requirement"];
    }
    if (issue.featureKey) {
      return [issue.featureKey, "feature"];
    }
    if (issue.improvementKey) {
      return [issue.improvementKey, "improvement"];
    }
    if (issue.taskKey) {
      return [issue.taskKey, "task"];
    }
    if (issue.bugKey) {
      return [issue.bugKey, "bug"];
    }
    if (issue.faultKey) {
      return [issue.faultKey, "fault"];
    }
    if (issue.serviceKey) {
      return [issue.serviceKey, "service"];
    }
  }

  render() {
    const data = this.filterData(this.state.data);
    let filteredIssues = [];
    if (this.state.filter.issues && this.state.filter.issues.length > 0) {
      filteredIssues = this.getFilteredIssues(data);
    }

    return (
      <>
        <Paper className="graphPage">
          <h1>Graphs</h1>
          <Grid container direction="row" spacing={2} alignItems="flex-end">
            <Grid item>
              <TextField
                id="issue-key"
                label="Issue keys"
                onChange={this.filterIssue.bind(this)}
                value={this.state.filter.issuesString}
              />
            </Grid>
            <Grid item>
              <FormControl style={{ minWidth: "10em" }}>
                <InputLabel id="role-type-filter-label">Role type</InputLabel>
                <Select
                  labelId="role-type-filter-label"
                  id="role-type-filter"
                  label="Role type"
                  value={this.state.filter.roleType}
                  onChange={this.filterRoleType.bind(this)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ANL">ANL</MenuItem>
                  <MenuItem value="DEV">DEV</MenuItem>
                  <MenuItem value="TST">TST</MenuItem>
                  <MenuItem value="UX">UX</MenuItem>
                  <MenuItem value="SUP">SUP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl style={{ minWidth: "10em" }}>
                <InputLabel id="role-type-filter-label">Issue type</InputLabel>
                <Select
                  labelId="role-type-filter-label"
                  id="role-type-filter"
                  label="Issue type"
                  value={this.state.filter.issueType}
                  onChange={this.filterIssueType.bind(this)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Requirement">Requirement</MenuItem>
                  <MenuItem value="Feature">Feature</MenuItem>
                  <MenuItem value="Improvement">Improvement</MenuItem>
                  <MenuItem value="Task">Task</MenuItem>
                  <MenuItem value="Bug">Bug</MenuItem>
                  <MenuItem value="Fault">Fault</MenuItem>
                  <MenuItem value="Service">Service</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                id="invoice-label"
                label="Invoice label"
                onChange={this.filterInvoiceLabel.bind(this)}
                value={this.state.filter.invoiceLabel}
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid item>
                <DesktopDatePicker
                  label="From"
                  inputFormat="YYYY-MM-DD"
                  value={this.state.from}
                  onChange={this.filterFromDate.bind(this)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item>
                <DesktopDatePicker
                  label="Until"
                  inputFormat="YYYY-MM-DD"
                  value={this.state.until}
                  onChange={this.filterUntilDate.bind(this)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>

            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={
                        this.state.filter.zv === "all" ? "primary" : "secondary"
                      }
                      checked={this.state.filter.zv !== "none"}
                      onChange={this.filterZV.bind(this)}
                    />
                  }
                  label="ZV"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <FilterStorage
            ref={this.filterStorage}
            context="graphs"
            loadFilter={this.handleLoadFilter.bind(this)}
            currentFilter={this.state.filter}
            defaultFilter={{
              issues: [],
              issuesString: "",
              issueType: "",
              roleType: "",
              invoiceLabel: "",
              zv: "all",
            }}
          />
          {this.state.filter.issues &&
          this.state.filter.issues.length > 0 &&
          filteredIssues &&
          filteredIssues.length > 0 ? (
            <p>
              Showing results for issues:
              {filteredIssues.map((item, i) => {
                let image = "";
                console.log(item);
                switch (item.issueType) {
                  case "requirement":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10515&avatarType=issuetype";
                    break;
                  case "feature":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10500&avatarType=issuetype";
                    break;
                  case "improvement":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=14101&avatarType=issuetype";
                    break;
                  case "task":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10518&avatarType=issuetype";
                    break;
                  case "bug":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10503&avatarType=issuetype";
                    break;
                  case "fault":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=12812&avatarType=issuetype";
                    break;
                  case "service":
                    image =
                      "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=13608&avatarType=issuetype";
                    break;
                  default:
                    break;
                }
                return (
                  <span key={i} className="issueListItem">
                    <img src={image} alt={item.issueType} />
                    <a
                      key={i}
                      href={"https://jira.ims.jsft.io/browse/" + item.key}
                    >
                      {item.key}
                    </a>
                  </span>
                );
              })}
            </p>
          ) : null}
          <RoleType data={data} />
          <ActivityType data={data} />
          <RoleTypeOverall data={data} />
          <FunctionalNonfunctional data={data} />
          <ContractOverall data={data} />
        </Paper>
      </>
    );
  }
}
