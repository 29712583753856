import React from 'react'

import './styles/app.sass';

import { AppBar, Toolbar, Box, Snackbar, Button, TextField, Paper } from '@mui/material'

import MuiAlert from '@mui/material/Alert'

import EpicPage from './EpicPage'
import RequirementPage from './RequirementPage'
import FeaturePage from './FeaturePage'
import GraphPage from './GraphPage'
import AllocatedPage from './AllocatedPage'
import VersionPage from './VersionPage'
import PeoplePage from './PeoplePage'
import ComparePage from './ComparePage'
import ScryptPage from './ScryptPage'

import './styles/login.sass'

import { IAMROOT } from './api-config'

import { createBrowserHistory } from 'history'
import PoCPage from './PoCPage';

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default class App extends React.Component {
  navItems = [
    'Epic',
    'Requirement',
    'Feature',
    'Graph',
    'Allocated',
    'Version',
    'People',
    'Compare',
    'Scrypt',
  ]
  state = {
    page: window.location.pathname.substring(1),

    username: "",
    password: "",
    alert: null,

    loggedIn: false,
    jwtResponse: {},
    snackbarOpen: false
  }

  componentDidUpdate() {
    if (this.state.loggedIn && this.state.jwtResponse.expiry && new Date(this.state.jwtResponse.expiry) < new Date()) {
      this.setState({
        loggedIn: false,
        jwtResponse: {},
      })
    }    
  }

  componentDidMount() {
    this.history = createBrowserHistory()

    const jwt = localStorage.getItem('jwt')
    if (jwt !== null) {
      const jwtResponse = JSON.parse(localStorage.getItem('jwt'))
      const jwtPayload = parseJwt(jwtResponse.jwt)
      
      const expiry = new Date(jwtResponse.expiry)
      const now = new Date()
      if (expiry > now) {
        this.setState({
          loggedIn: true,
          jwtResponse: jwtResponse,
          username: jwtPayload.u,
        })

        this.renew(jwtResponse.jwt)
      }
    }

    if (this.state.page === "") {
      this.setState({page: this.navItems[0].toLowerCase()})
    }

    this.history.listen((a) => {
      if (a.action === "POP") {
        const el = this.navItems.find(element => element.toLowerCase() === window.location.pathname.substring(1))
        const nix = this.navItems.indexOf(el)
        
        this.setState({
          page: (nix === -1) ? this.navItems[0].toLowerCase() : this.navItems[nix].toLowerCase()
        })
      }
    })
  }

  handlePageChange(newValue) {
    let nix = this.navItems.indexOf(newValue)
    if (nix === -1) {
      return  
    }
    
    
    this.history.push(("/") + this.navItems[nix].toLowerCase())
    this.setState({
      page: this.navItems[nix].toLowerCase()
    })
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value
    })
  }

  async login(username, password) {
    const response = await fetch(IAMROOT + "/login", {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      })
    })

    if (response.status !== 200) {
      return false
    }

    const jwtResponse = await response.json()

    this.setState({
      jwtResponse: jwtResponse,
      loggedIn: true,
      username: username
    })

    localStorage.setItem('jwt', JSON.stringify(jwtResponse))

    setTimeout(() => {
      this.renew(this.state.jwtResponse.jwt)
    }, 240000)
    return true
  }

  async renew(jwt) {
    const response = await fetch(IAMROOT + "/renew", {
      method: 'POST',
      body: jwt
    })

    if (response.status !== 200) {
      return false
    }

    const jwtResponse = await response.json()
    this.setState({
      jwtResponse: jwtResponse,
      loggedIn: true
    })

    localStorage.setItem('jwt', JSON.stringify(jwtResponse))

    setTimeout(() => {
      this.renew(this.state.jwtResponse.jwt)
    }, 240000)
    return true
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }

    this.setState({snackbarOpen: false})
  }

  async handleFormSubmit(e) {
    e.preventDefault()

    let success = await this.login(this.state.username, this.state.password)
    if (success === false) {
      this.setState({
        snackbarOpen: true,
        alert: (
          <Alert onClose={this.handleClose.bind(this)} severity="error">Wrong username or password</Alert>
        )
      })
    } else {
      this.setState({
        snackbarOpen: true,
        alert: (
          <Alert onClose={this.handleClose.bind(this)} severity="success">Logged in successfully</Alert>
        )
      })
    }
  }

  render() {
    if (!this.state.loggedIn) {
      return (
        <Paper
          className="loginPage">
          <form onSubmit={this.handleFormSubmit.bind(this)}>
            <div>
              <TextField id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.username}
                />
            </div>
            <div>
              <TextField id="password"
                label="Password"
                type="password"
                name="password"
                autoComplete="current-password"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.password}
                />
            </div>
            <div>
              <Button type="submit" variant="contained" disabled={(this.state.password === "")} color="primary">
                Login
              </Button>
            </div>
          </form>
        </Paper>
      )
    }

    if (this.state.username === "UrbanO" && (this.navItems.indexOf('POC') === -1)) {
      this.navItems.push('POC')
    }

    return (
      <>
        <Snackbar open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.handleClose.bind(this)}>
          <div>
            {this.state.alert}
          </div>
          
        </Snackbar>

          {(this.state.loggedIn) ? (
            <AppBar>
              <Toolbar>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {this.navItems.map((item) => (
                  <Button
                    onClick={this.handlePageChange.bind(this, item)}
                    key={item}
                    sx={{ color: '#fff' }}>
                    {item}
                  </Button>
                ))}
              </Box>
              </Toolbar>
            </AppBar>
          ) : null}
        <Page currentPage={this.state.page} page="epic">
          <EpicPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="requirement">
          <RequirementPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="feature">
          <FeaturePage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="graph">
          <GraphPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="allocated">
          <AllocatedPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="version">
          <VersionPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="people">
          <PeoplePage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="compare">
          <ComparePage jwt={this.state.jwtResponse.jwt} />
        </Page>
        <Page currentPage={this.state.page} page="scrypt" >
          <ScryptPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        {(this.state.username === "UrbanO") ? (
        <Page currentPage={this.state.page} page="poc">
          <PoCPage jwt={this.state.jwtResponse.jwt} />
        </Page>
        ) : null}
      </>
    )
  }
}

class Page extends React.Component {
  render() {
    return(
      <>
        {(this.props.currentPage === this.props.page) ? (
          this.props.children
        ): null}
      </>
    )
  }
}


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
