import React from 'react'

import { Paper, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import RequirementList from './RequirementList'

import FilterStorage from './components/FilterStorage'

import './styles/cards.sass'

export default class RequirementPage extends React.Component {
  state = {
    filter: {
      issues: [],
      issuesString: "",
      label: "",
      version: "",
      status: ["Open","Requirement Definition","Assign Analyst","In Analysis","Analysis Completed","Customer Handover","Awaiting Approval","Create Features","Features In Progress","Awaiting Final Approval"],
      statusString: "Open,Requirement Definition,Assign Analyst,In Analysis,Analysis Completed,Customer Handover,Awaiting Approval,Create Features,Features In Progress,Awaiting Final Approval",
    },
  }

  constructor(props) {
    super(props)
    this.filterStorage = React.createRef()
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter
    })
  }

  filterIssue(e) {
    const regex = /[1-9][0-9]*/g
    const issueKeys = e.target.value.match(regex)
    let state = this.state

    this.filterStorage.current.cancelAllExcept()

    state.filter.issues = issueKeys
    state.filter.issuesString = e.target.value
    this.setState(state)
  }

  filterLabel(e) {
    let state = this.state
    state.filter.label = e.target.value

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterVersion(e) {
    let state = this.state
    state.filter.version = e.target.value

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  filterStatus(e) {
    let state = this.state
    state.filter.statusString = e.target.value
    const regex = /[a-zA-Z ]+/g
    state.filter.status = e.target.value.match(regex)

    this.filterStorage.current.cancelAllExcept()

    this.setState(state)
  }

  render() {
    return (
      <Paper className="mainList requirementList">
        <h1>Requirements</h1>
        <Grid container
          direction="row"
          spacing={2}
          >
          <Grid item>
            <TextField id="issue-key"
              label="Issue keys"
              onChange={this.filterIssue.bind(this)}
              value={this.state.filter.issuesString}
              />
          </Grid>
          <Grid item>
            <TextField id="label"
              label="Label"
              onChange={this.filterLabel.bind(this)}
              value={this.state.filter.label}
              />
          </Grid>
          <Grid item style={{minWidth: '15em'}}>
            <FormControl fullWidth>
              <InputLabel id="select-label-status">Status</InputLabel>
              <Select
                labelId="select-label-status"
                id="select-status"
                label="Status"
                value={this.state.filter.statusString}
                onChange={this.filterStatus.bind(this)}
                >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem
                value="Open,Requirement Definition,Assign Analyst,In Analysis,Analysis Completed,Customer Handover,Awaiting Approval,Create Features,Features In Progress,Awaiting Final Approval"
                >
                Active
              </MenuItem>
              <MenuItem value="Open,Requirement Definition">Requirement Definition</MenuItem>
              <MenuItem value="Assign Analyst,In Analysis,Analysis Completed,Customer Handover">Analysis</MenuItem>
              <MenuItem value="Awaiting Approval">Awaiting Approval</MenuItem>
              <MenuItem value="Create Features,Features In Progress">Features</MenuItem>
              <MenuItem value="Awaiting Final Approval">Awaiting Final Approval</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
            </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FilterStorage
          ref={this.filterStorage}
          context="requirements"
          loadFilter={this.handleLoadFilter.bind(this)}
          currentFilter={this.state.filter}
          defaultFilter={{
            issues: [],
            issuesString: "",
            label: "",
            status: ["Open","Requirement Definition","Assign Analyst","In Analysis","Analysis Completed","Customer Handover","Awaiting Approval","Create Features","Features In Progress","Awaiting Final Approval"],
            statusString: "Open,Requirement Definition,Assign Analyst,In Analysis,Analysis Completed,Customer Handover,Awaiting Approval,Create Features,Features In Progress,Awaiting Final Approval",
          }}
          />
        <RequirementList
          filter={this.state.filter}
          jwt={this.props.jwt}
          />
      </Paper>
    )
  }
}
