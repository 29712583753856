import React from 'react'

import { TextField, IconButton, Chip } from '@mui/material'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import '../styles/filterStorage.sass'

export default class FilterStorage extends React.Component {
  state = {
    saving: false,
    name: "",
    filters: (localStorage.getItem('filters/'+this.props.context) !== null) ? JSON.parse(localStorage.getItem('filters/'+this.props.context)) : [],
  }

  handleCancel() {
    if (this.state.saving) {
      this.toggleSave(null, true)
    } else {
      this.props.loadFilter(this.props.defaultFilter)
      this.cancelAllExcept()
    }
  }

  deleteItem(index) {
    let filters = this.state.filters
    filters.splice(index, 1)

    localStorage.setItem('filters/' + this.props.context, JSON.stringify(filters))

    this.items = []
    filters.forEach((item, i) => {
      this.items[i] = React.createRef()
    })
    this.setState({filters: filters})
  }

  cancelAllExcept(index = -1) {
    this.items.forEach((item, i) => {
      // console.log(i, index)
      if (i !== index) {
        item.current.superCancel()
      }
    })
  }

  constructor(props) {
    super(props)
    this.items = []
    this.state.filters.forEach((item, i) => {
      this.items[i] = React.createRef()
    })
  }

  toggleSave(e, cancel = false) {
    if (this.state.saving && !cancel && this.state.name === "") {
      // ak sme nevyplnili meno a nestlacili cancel
      return
    } else if (this.state.saving && !cancel && this.state.name !== "") {
      // ak sme vyplnili meno a nestlacili cancel
      const curFilter = this.props.currentFilter
      const newFilterItem = {
        id: (this.state.filters.length > 0) ? (this.state.filters[this.state.filters.length - 1].id + 1) : 1,
        name: this.state.name,
        filter: curFilter,
      }

      let filters = [...this.state.filters, newFilterItem]

      localStorage.setItem('filters/'+this.props.context, JSON.stringify(filters))

      this.items = []
      filters.forEach((item, i) => {
        this.items[i] = React.createRef()
      })
      this.setState({filters: filters})
    }
    this.setState({saving: !this.state.saving, name: ""})
  }

  handleSubmit(e) {
    e.preventDefault()
    this.toggleSave()
  }

  changeName(e) {
    this.setState({name: e.target.value})
  }

  getSave() {
    if (this.state.saving) {
      return (
        <>
          <TextField id="filterName"
            label="Filter name"
            onChange={this.changeName.bind(this)}
            value={this.name}
            />
          <IconButton onClick={this.toggleSave.bind(this)} color="primary">
            <SaveIcon />
          </IconButton>
        </>
      )
    } else {
      return (
        <IconButton onClick={this.toggleSave.bind(this)} color="primary">
          <SaveIcon />
        </IconButton>
      )
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="filterStorage">
        {this.getSave()}

        <IconButton color="secondary" onClick={this.handleCancel.bind(this)}>
          <ClearIcon />
        </IconButton>

        {this.state.filters.map((item, k) => {
          return (
            <SavedFilter
              key={item.id}
              k={k}
              ref={this.items[k]}
              loadFilter={this.props.loadFilter}
              filter={item.filter}
              defaultFilter={this.props.defaultFilter}
              label={item.name}
              cancelAllExcept={this.cancelAllExcept.bind(this)}
              deleteItem={this.deleteItem.bind(this)}
              />
          )
        })}

      </form>
    )
  }
}

class SavedFilter extends React.Component {
  state = {
    deletion: 0,
    active: false
  }

  superCancel() {
    this.setState({active: false})
  }

  handleClick() {
    if (!this.state.active) {
      // toto je hrozne skarede - ale je to workaround,
      // na nieco co nechapem - prepisoval sa filter (asi sa passoval pointer)
      this.props.loadFilter({...this.props.filter})
      this.setState({active: true})
      this.props.cancelAllExcept(this.props.k)
    } else {
      this.props.loadFilter(this.props.defaultFilter)
      this.setState({active: false})
    }
  }

  handleDelete() {
    switch (this.state.deletion) {
      case 0:
        this.setState({deletion: 1})
         this.deleteTimeout = setTimeout((() => {
           this.deleteTimeout = false
           this.setState({deletion: 0})
         }), 600)
        break
      case 1:
        clearTimeout(this.deleteTimeout)
        this.props.deleteItem(this.props.k)
        break
      default:
    }
  }

  render() {
    return (
      <span className="chip">
        <Chip
          color={(this.state.deletion === 1) ? "secondary" : ((this.state.active) ? "primary" : "default")}
          onClick={this.handleClick.bind(this)}
          onDelete={this.handleDelete.bind(this)}
          label={this.props.label} />
      </span>
    )
  }
}
