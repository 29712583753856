import React from 'react'

import { Paper } from '@mui/material'

import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Bar, Label, Legend } from 'recharts'

import { colours } from '../colours'


export default class VersionGraphSpent extends React.Component {
  prepareData(origData) {
    let data = []

    origData.forEach((item, i) => {
      const bugs = item.bugs||[]
      let reopened = item.reopenedBugs||[]

      let bugsWithoutReopened = bugs.filter((b) => {
        let reopenedIndex = reopened.findIndex((r) => {
          return r.key === b.key
        })
        return (reopenedIndex === -1) && (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
      })


      if (reopened && reopened.length > 1) {
        reopened = reopened.filter((b) => {
          return (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
        })
      }

      let bwr = 0, r = 0, f = 0, bf = 0
      for (let i in bugsWithoutReopened) {
        bwr += bugsWithoutReopened[i].logged.ANL +
          bugsWithoutReopened[i].logged.DEV +
          bugsWithoutReopened[i].logged.TST +
          bugsWithoutReopened[i].logged.SUP +
          bugsWithoutReopened[i].logged.UX
      }
      for (let i in reopened) {
        r += reopened[i].logged.ANL +
          reopened[i].logged.DEV +
          reopened[i].logged.TST +
          reopened[i].logged.SUP +
          reopened[i].logged.UX
      }
      for (let i in item.featuresFixed) {
        f += item.featuresFixed[i].logged.ANL +
          item.featuresFixed[i].logged.DEV +
          item.featuresFixed[i].logged.TST +
          item.featuresFixed[i].logged.SUP +
          item.featuresFixed[i].logged.UX
      }
      for (let i in item.bugsFixed) {
        bf += item.bugsFixed[i].logged.ANL +
          item.bugsFixed[i].logged.DEV +
          item.bugsFixed[i].logged.TST +
          item.bugsFixed[i].logged.SUP +
          item.bugsFixed[i].logged.UX
      }
      // konverzia na MDs
      bwr = Math.round(bwr / 288) / 100
      r = Math.round(r / 288) / 100
      f = Math.round(f / 288) / 100
      bf = Math.round(bf / 288) / 100

      data[data.length] = {
        'name': item.name,
        'date': ((item.releaseDate) ? (" " + item.releaseDate) : ""),
        'New bugs': bwr,
        'Reopened bugs': r,
        'Features fixed': f,
        'Bugs fixed': bf,
      }
    })

    return data.reverse()
  }

  render() {
    const data = this.prepareData(this.props.data)
    return(
      <Paper
        className="version">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart width={730} height={250} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}>
            <defs>
              <linearGradient id="colorNew" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colours["orange"]} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colours["orange"]} stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorReopened" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colours["red"]} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colours["red"]} stopOpacity={0}/>
              </linearGradient>
            </defs>
            <Legend verticalAlign="top" />
            <XAxis dataKey="name">
              <Label value="version" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              label={{ value: 'MDs', angle: -90, position: 'insideLeft' }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip labelFormatter={(value) => {
              const v = this.props.data.filter((d) => {
                return d.name === value
              })
              if (v.length === 1 && (v[0].releaseDate !== null || v[0].releaseDate !== "")) {
                return value + " (" + v[0].releaseDate+ ")" + ((v[0].released) ? "" : " not released")
              }

              return value
            }} />
            <Bar dataKey="Features fixed" stackId="x" barSize={20} fill={colours["grassGreen"]} />
            <Bar dataKey="Bugs fixed" stackId="x" barSize={20} fill={colours["red"]} />
            <Area type="monotone" dataKey="New bugs" stroke={colours["orange"]} fillOpacity={1} fill="url(#colorNew)" />
            <Area type="monotone" dataKey="Reopened bugs" stroke={colours["red"]} fillOpacity={1} fill="url(#colorReopened)" />
          </ComposedChart>
        </ResponsiveContainer>
      </Paper>
    )
  }
}
