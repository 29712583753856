import React from 'react'

import { Paper, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import RoleType from './graphs/allocated/RoleType'


import './styles/graph.sass'

import { APIROOT } from './api-config.js'

export default class GraphPage extends React.Component {
  state = {
    data: [],
    filter: {
      issues: [],
      issuesString: "",
      issueType: "",
      roleType: "",
      invoiceLabel: "",
      status: ["Open","In Analysis","In Review","Customer Handover","Customer Review","Awaiting Implementation","Implementing","Implemented","Code Review","Ready To Test","Testing Issue","Acceptance"],
      statusString: "Open,In Analysis,In Review,Customer Handover,Customer Review,Awaiting Implementation,Implementing,Implemented,Code Review,Ready To Test,Testing Issue,Acceptance",
    },
  }

  componentDidMount() {
    this.getData()
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter
    })
  }

  async getData() {
    const response = await fetch(APIROOT + "/features/estimates", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    this.setState({
      data: await response.json()
    })
  }

  filterIssue(e) {
    const regex = /[1-9][0-9]*/g
    const issueKeys = e.target.value.match(regex)
    let state = this.state

    state.filter.issues = issueKeys
    state.filter.issuesString = e.target.value
    this.setState(state)
  }


  filterStatus(e) {
    let state = this.state
    state.filter.statusString = e.target.value
    const regex = /[a-zA-Z ]+/g
    state.filter.status = e.target.value.match(regex)

    this.setState(state)
  }

  
  filterData(data) {
    // ofiltrovanie podla issue key parametra
    if (this.state.filter.issues && this.state.filter.issues.length !== 0) {
      const filterIssues = this.state.filter.issues
      data = data.filter(function (d) {
        if (d.keyAggregate) {
          return d.keyAggregate.some(r => filterIssues.indexOf(r.toString()) >= 0)
        } else {
          return false
        }
      })
    }

    
    // ofiltrovanie podla satus parametra
    if (this.state.filter.status && this.state.filter.status.length !== 0) {
      const filterStatus = this.state.filter.status
      data = data.filter(function (d) {
        return filterStatus.indexOf(d.status) >= 0
      })
    }

    if (this.state.filter.roleType && this.state.filter.roleType !== "") {
      const filterRoleType = this.state.filter.roleType
      data = data.filter(function (d) {
        return d.roleType === filterRoleType
      })
    }

    if (this.state.filter.issueType && this.state.filter.issueType !== "") {
      const filterIssueType = this.state.filter.issueType
      data = data.filter(function (d) {
        return ((d.requirementKey)?'Requirement':((d.taskKey)?'Task':((d.featureKey)?'Feature':((d.bugKey)?'Bug':((d.serviceKey)?'Service':null))))) === filterIssueType
      })
    }

    if (this.state.filter.invoiceLabel && this.state.filter.invoiceLabel !== "") {
      const filterInvoiceLabel = this.state.filter.invoiceLabel
      data = data.filter(function (d) {
        return d.invoiceLabel === parseInt(filterInvoiceLabel)
      })
    }
    return data
  }
  

  render() {
    const data = this.filterData(this.state.data)
    
    return (
      <>
        <Paper className="graphPage">
          <h1>Allocated in Features</h1>
          <Grid container
            direction="row"
            spacing={2}
            >
            <Grid item>
              <TextField id="issue-key"
                label="Issue keys"
                onChange={this.filterIssue.bind(this)}
                value={this.state.filter.issuesString}
                />
            </Grid>
            <Grid item>
              <FormControl style={{ minWidth: "10em" }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  value={this.state.filter.statusString}
                  onChange={this.filterStatus.bind(this)}
                  >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem
                    value="Open,In Analysis,In Review,Customer Handover,Customer Review,Awaiting Implementation,Implementing,Implemented,Code Review,Ready To Test,Testing Issue,Acceptance"
                    >
                    Active
                  </MenuItem>
                  <MenuItem value="Open,In Analysis,In Review,Customer Handover">Analysis</MenuItem>
                  <MenuItem value="Customer Review">Customer review</MenuItem>
                  <MenuItem value="Awaiting Implementation,Implementing,Implemented,Code Review">Implementation</MenuItem>
                  <MenuItem value="Ready To Test,Testing Issue">Testing</MenuItem>
                  <MenuItem value="Acceptance">Acceptance</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          
          <RoleType data={data} />
        </Paper>
      </>
    )
  }
}
