import React from 'react'

import { Paper } from '@mui/material'

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts'

import { colours } from './colours'

export default class Version extends React.Component {
  render() {
    const data = this.props.data
    const bugs = data.bugs||[]
    let reopened = data.reopenedBugs||[]

    let reopenedCount = 0
    if (reopened && reopened.length > 1) {
      reopenedCount = reopened.filter((b) => {
        return (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
      })
    }

    let bugsWithoutReopened = bugs.filter((b) => {
      let reopenedIndex = reopened.findIndex((r) => {
        return (r.key === b.key)
      })
      return (reopenedIndex === -1)
    })
    const bugsWithoutReopenedCount = bugsWithoutReopened.filter((b) => {
      return (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
    })

    const bugData = [
      {
        name: 'bugs',
        sum: bugsWithoutReopenedCount.length
      },
      {
        name: 'reopened bugs',
        sum: reopenedCount.length
      }
    ]
    return (
      <Paper
        className="version">
        <h2>{data.name}</h2>
        {(data.released === true) ? (
          <p>
            Release date: {data.releaseDate}
          </p>
        ) : null}
        <h3>Resolved</h3>
        <p>
          {(data.featuresFixed && data.featuresFixed.length > 0) ? (
            <>
              {data.featuresFixed.map((feature, key) => {
                return (
                  <span
                    className="issueListItem"
                    key={key}>
                    <img
                      src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10500&avatarType=issuetype"
                      alt="feature" />
                    <a
                      href={"https://jira.ims.jsft.io/browse/" + feature.key}>
                      {feature.key}
                    </a>
                  </span>
                )
              })}
            </>
          ) : null}
          {(data.bugsFixed && data.bugsFixed.length > 0) ? (
            <>
              {data.bugsFixed.map((bug, key) => {
                return (
                  <span
                    className="issueListItem"
                    key={key}>
                    <img
                      src="https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10503&avatarType=issuetype"
                      alt="bug" />
                    <a
                      href={"https://jira.ims.jsft.io/browse/" + bug.key}>
                      {bug.key}
                    </a>
                  </span>
                )
              })}
            </>
          ) : null}
        </p>

        {(bugs.length > 0 || reopened.length > 0) ? (
          <>
            <h3>Introduced</h3>
            <BarChart width={300} height={200} data={bugData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="sum" fill={colours["red"]} />
            </BarChart>
            {(bugsWithoutReopened.length > 0) ? (
              <p>
                New:
                {bugsWithoutReopened.map((bug, key) => {
                  return (
                    <span
                      className={"issueListItem" +
                        ((bug.resolution.id === "2" || bug.resolution.id === "3" || bug.resolution.id === "5") ? " notBug" :
                          ((bug.resolution.id !== "" && (bug.fixVersions && bug.fixVersions.length > 0 && bug.fixVersions.filter((v) => {
                            return (v.released && bug.affectsVersions.indexOf(v) === -1)
                          }).length > 0)) ? " releasedBug" :
                            ((bug.resolution.id !== "") ? " resolvedBug" : "")
                        )
                      )}
                      key={key}>
                      <img
                        src={bug.priority.iconUrl}
                        alt="bug" />
                      <a
                        href={"https://jira.ims.jsft.io/browse/" + bug.key}>
                        {bug.key}
                      </a>
                    </span>
                  )
                })}
              </p>
            ) : null}
            {(reopened.length > 0) ? (
              <p>
                Reopened:
                {reopened.map((bug, key) => {
                  return (
                    <span
                      className={"issueListItem" +
                        ((bug.resolution.id === "2" || bug.resolution.id === "3" || bug.resolution.id === "5") ? " notBug" :
                          ((bug.resolution.id !== "" && (bug.fixVersions && bug.fixVersions.length > 0 && bug.fixVersions.filter((v) => {
                            return (v.released && bug.affectsVersions.indexOf(v) === -1)
                          }).length > 0)) ? " releasedBug" :
                            ((bug.resolution.id !== "") ? " resolvedBug" : "")
                        )
                      )}
                      key={key}>
                      <img
                        src={bug.priority.iconUrl}
                        alt="bug" />
                      <a
                        href={"https://jira.ims.jsft.io/browse/" + bug.key}>
                        {bug.key}
                      </a>
                    </span>
                  )
                })}
              </p>
            ) : null}
          </>
        ) : null}
      </Paper>
    )
  }
}
