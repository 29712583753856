import React from 'react'

import { Paper, TextField, Grid } from '@mui/material'

import FilterStorage from './components/FilterStorage'

import EpicList from './EpicList'

export default class EpicPage extends React.Component {
  state = {
    filter: {
      issues: [],
      issuesString: "",
      label: ""
    },
  }

  constructor(props) {
    super(props)
    this.filterStorage = React.createRef()
  }

  handleLoadFilter(filter) {
    this.setState({
      filter: filter
    })
  }

  filterIssue(e) {
    const regex = /[1-9][0-9]*/g
    const issueKeys = e.target.value.match(regex)

    this.filterStorage.current.cancelAllExcept()

    this.setState({
      filter: {
        issues: issueKeys,
        issuesString: e.target.value,
        label: this.state.filter.label,
      }
    })
  }

  filterLabel(e) {
    let state = this.state
    this.filterStorage.current.cancelAllExcept()

    state.filter.label = e.target.value

    this.setState(state)
  }

  render() {
    return (
      <Paper className="mainList">
        <h1>Epics</h1>
        <Grid container
          direction="row"
          spacing={2}>
          <Grid item>
            <TextField id="issue-key"
              label="Issue keys"
              onChange={this.filterIssue.bind(this)}
              value={this.state.filter.issuesString}
              />
          </Grid>
          <Grid item>
            <TextField id="label"
              label="Label"
              onChange={this.filterLabel.bind(this)}
              value={this.state.filter.label}
              />
          </Grid>
        </Grid>
        <FilterStorage
          ref={this.filterStorage}
          context="epics"
          loadFilter={this.handleLoadFilter.bind(this)}
          currentFilter={this.state.filter}
          defaultFilter={{
            issues: [],
            issuesString: "",
            label: ""
          }}
          />
        <EpicList
          filter={this.state.filter}
          jwt={this.props.jwt}
          />
      </Paper>
    )
  }
}
