import React from 'react'

import { Paper, Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { APIROOT } from './api-config.js'

export default class ComparePage extends React.Component {
  state = {
    data: [],
    estimation: "logged",
    versionA: null,
    versionB: null,
    diff: null,
    loading: false
  }
  componentDidMount() {
    this.getVersionListData()
    this.months = []

    for (let date = new Date(2020, 0, 1); date <= new Date();date.setMonth(date.getMonth()+1)) {
      this.months[this.months.length] = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2)
    }
  }

  async getVersionListData() {
    const response = await fetch(APIROOT + "/versions?detail=none&released=true", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      }
    })

    if (response.status !== 200) {
      return []
    }

    const data = await response.json()
    let outData = []
    for(let i=0; i<data.length;i++) {
      outData[i] = {
        label: data[i].name
      }
    }

    this.setState({
      data: outData
    })
  }

  async compareVersions(versionA, versionB) {
    this.setState({loading: true})
    const response = await fetch(APIROOT + "/versions/compare", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.jwt,
      },
      body: JSON.stringify([versionA.label, versionB.label])
    })
    if (response.status !== 200) {
      this.setState({loading: false})
      return []
    }

    const data = await response.json()

    this.setState({
      diff: data,
      loading: false
    })
  }

  render() {
    const diff = this.state.diff
    
    return(
      <Paper className="people">
        <h1>Compare versions</h1>
        <Grid container
          direction="row"
          spacing={2}
          style={{marginBottom: "2em"}}
          >
          <Grid item>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={this.state.data}
              value={this.state.versionA}
              onChange={(event, newValue) => {
                this.setState({versionA: newValue, diff: null})
                if (this.state.versionB && newValue) {
                  this.compareVersions(newValue, this.state.versionB)
                }
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Version A" />}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={this.state.data}
              value={this.state.versionB}
              onChange={(event, newValue) => {
                this.setState({versionB: newValue, diff: null})
                if (this.state.versionA && newValue) {
                  this.compareVersions(this.state.versionA, newValue)
                }
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Version B" />}
            />
          </Grid>
        </Grid>
        {(diff === null) ? ((this.state.loading ) ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : null) : ((diff.error !== "") ? (
          <pre>The comparison returned an error: {diff.error}</pre>
        ) : (
          <>
            <h2>Public</h2>
            <p>
              {(diff["public_urls"].addedLines !== null) ? diff["public_urls"].addedLines.map((v, k) => {
                  return (
                    <span style={{color: "green"}} key={k}>+ {v}<br /></span>
                  )
              }) : null}
              {(diff["public_urls"].removedLines !== null) ? diff["public_urls"].removedLines.map((v, k) => {
                  return (
                    <span style={{color: "red"}} key={k}>- {v}<br /></span>
                  )
              }) : null}
            </p>
            <h2>Private</h2>
            <p>
              {(diff["private_urls"].addedLines !== null) ? diff["private_urls"].addedLines.map((v, k) => {
                  return (
                    <span style={{color: "green"}} key={k}>+ {v}<br /></span>
                  )
              }) : null}
              {(diff["private_urls"].removedLines !== null) ? diff["private_urls"].removedLines.map((v, k) => {
                  return (
                    <span style={{color: "red"}} key={k}>- {v}<br /></span>
                  )
              }) : null}
            </p>
            {(diff.zoak_urls) ? null :
              <>
                <h2>ZoAK</h2>
                <p>
                  {(diff["zoak_urls"].addedLines !== null) ? diff["zoak_urls"].addedLines.map((v, k) => {
                      return (
                        <span style={{color: "green"}} key={k}>+ {v}<br /></span>
                      )
                  }) : null}
                  {(diff["zoak_urls"].removedLines !== null) ? diff["zoak_urls"].removedLines.map((v, k) => {
                      return (
                        <span style={{color: "red"}} key={k}>- {v}<br /></span>
                      )
                  }) : null}
                </p>
              </>
            }
            <h2>EPV</h2>
            <p>
              {(diff["epv_urls"].addedLines !== null) ? diff["private_urls"].addedLines.map((v, k) => {
                  return (
                    <span style={{color: "green"}} key={k}>+ {v}<br /></span>
                  )
              }) : null}
              {(diff["epv_urls"].removedLines !== null) ? diff["private_urls"].removedLines.map((v, k) => {
                  return (
                    <span style={{color: "red"}} key={k}>- {v}<br /></span>
                  )
              }) : null}
            </p>
          </>
        ))}
      </Paper>
    )
  }
}
