import React from 'react'


import { Paper } from '@mui/material'

import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts'

import { colours } from '../colours'

export default class FunctionalNonfunctional extends React.Component {
  prepareData(origData) {
    let functional = 0
    let nonfunctional = 0

    origData.map((item, key) => {
      switch(item.epicKey) {
        case 55:
        case 56:
        case 57:
        case 58:
        case 59:
        case 100:
          nonfunctional += item.timeSpent
          break;
        default:
          functional += item.timeSpent
      }
      return null
    })

    const timeSpent = nonfunctional + functional

    let data = []
    
    data[0] = {
      name: "Functional",
      sum: Math.round(functional / 36 / 8) / 100,
      percentage: Math.round((functional / timeSpent) * 10000) / 100,
      color: colours.mauve
    }
    data[1] = {
      name: "Nonfunctional",
      sum: Math.round(nonfunctional / 36 / 8) / 100,
      percentage: Math.round((nonfunctional / timeSpent) * 10000) / 100,
      color: colours.turquoise
    }

    
    return data
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Functional and Nonfunctional requirements</h2>
        <PieChart width={400} height={400}>
          <Legend />
          <Tooltip formatter={(value, name, props) => { return value + " MDs; " + props.payload.percentage + " %" }} />
          <Pie data={data} dataKey="sum" nameKey="name" fill="#8884d8">
            {
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}/>
              ))
            }
          </Pie>
        </PieChart>
      </Paper>
    )
  }
}
