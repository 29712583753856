import React from "react";

import "./styles/epicList.sass";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

import ProgressBar from "./components/ProgressBar";
import Status from "./components/Status";

import { APIROOT } from "./api-config.js";

export default class EpicList extends React.Component {
  state = {
    epics: [],
    data: [],
  };

  componentDidMount() {
    this.getEpics();
  }

  async getEpics() {
    const response = await fetch(APIROOT + "/epicView", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.jwt,
      },
    });

    if (response.status !== 200) {
      return [];
    }

    const data = await response.json();
    let epicData = data.filter(function (d) {
      return d.Type === "epic";
    });

    // pridanie nefunkcnych poziadaviek ako sumy vsetkych
    // epicov
    epicData[epicData.length] = {
      Key: "",
      Type: "epic",
      EpicKey: "",
      RelKey: "",
      Labels: ["NP"],

      Summary: "NP: SUMA nefunkčných požiadaviek",

      Estimated: epicData
        .filter(
          (d) =>
            d.EpicKey === "IMS-55" ||
            d.EpicKey === "IMS-56" ||
            d.EpicKey === "IMS-57" ||
            d.EpicKey === "IMS-58" ||
            d.EpicKey === "IMS-59" ||
            d.EpicKey === "IMS-100"
        )
        .reduce((sum, current) => sum + current.Estimated, 0),
      InvoiceTime: 0,
      Remaining: 0,
    };

    this.setState({
      epics: epicData,
      data: data,
    });
  }

  filterData(epic) {
    // filter podla issue key
    if (
      this.props.filter.issuesString &&
      this.props.filter.issuesString !== ""
    ) {
      const filterIssues = this.props.filter.issuesString;
      let showEpics = [];

      this.state.data.forEach((d) => {
        if (d.Key.toLowerCase().includes(filterIssues.toLowerCase())) {
          showEpics = [...showEpics, d.EpicKey];
        }
      });

      epic = epic.filter(function (d) {
        return showEpics.indexOf(d.Key) >= 0;
      });
    }

    // filter podla label
    if (this.props.filter.label && this.props.filter.label !== "") {
      const filterLabel = this.props.filter.label;
      epic = epic.filter(function (d) {
        return d.Labels.some((r) => r.startsWith(filterLabel));
      });
    }

    return epic;
  }

  render() {
    let epics = this.filterData(this.state.epics);

    return (
      <div id="epicList">
        {epics.map((epic, key) => {
          return <Epic key={key} epicData={epic} data={this.state.data} />;
        })}
      </div>
    );
  }
}

class Epic extends React.Component {
  render() {
    const epic = this.props.epicData;
    const epicIssues = this.props.data.filter((d) => {
      return d.EpicKey === epic.Key && d.Type !== "epic";
    });

    // najdenie 100% sirky - najvacsia suma
    let largestTimeSum = 0;

    // vyrobenie objektu pre RQs
    const rqIssues = this.props.data.filter((d) => {
      return (
        (d.EpicKey === epic.Key && d.Type === "requirement") ||
        (epic.Key === "" &&
          d.Type === "requirement" &&
          (d.EpicKey === "IMS-55" ||
            d.EpicKey === "IMS-56" ||
            d.EpicKey === "IMS-57" ||
            d.EpicKey === "IMS-58" ||
            d.EpicKey === "IMS-59" ||
            d.EpicKey === "IMS-100"))
      );
    });

    // prejdenie priradenych issues a napocitanie do epicu
    rqIssues.forEach((d) => {
      d.Features = {
        TimeSpent: 0,
        InvoiceTime: 0,
        Remaining: 0,
      };
      epicIssues.forEach((i) => {
        if (i.RelKey === d.Key) {
          d.Features.TimeSpent += i.TimeSpent;
          d.Features.InvoiceTime += i.InvoiceTime;
          d.Features.Remaining += i.Remaining;
        }
      });

      if (
        d.TimeSpent +
          d.InvoiceTime +
          d.Remaining +
          d.Features.TimeSpent +
          d.Features.InvoiceTime +
          d.Features.Remaining >
        largestTimeSum
      ) {
        largestTimeSum =
          d.TimeSpent +
          d.InvoiceTime +
          d.Remaining +
          d.Features.TimeSpent +
          d.Features.InvoiceTime +
          d.Features.Remaining;
      }
    });

    // vyfiltrovanie len velkych issues
    const largeEpicIssues = epicIssues.filter(function (d) {
      if (d.TimeSpent + d.InvoiceTime + d.Remaining > largestTimeSum) {
        largestTimeSum = d.TimeSpent + d.InvoiceTime + d.Remaining;
      }
      return (
        d.Type !== "requirement" &&
        d.Type !== "feature" &&
        d.Remaining + d.TimeSpent + d.InvoiceTime > 4 * 3600 * 8
      );
    });

    // vyfiltrovanie vsetkych malych issues
    const smallEpicIssues = epicIssues.filter(function (d) {
      return (
        d.Type !== "requirement" &&
        d.Type !== "feature" &&
        d.Remaining + d.TimeSpent + d.InvoiceTime <= 4 * 3600 * 8
      );
    });

    let smallEpicIssuesTimeSum = 0;
    smallEpicIssues.forEach((d) => {
      smallEpicIssuesTimeSum += d.TimeSpent + d.InvoiceTime + d.Remaining;
    });
    if (smallEpicIssuesTimeSum > largestTimeSum) {
      largestTimeSum = smallEpicIssuesTimeSum;
    }

    // zratanie casov
    let invoiceTime = 0;
    let timeSpent = 0;
    let remaining = 0;
    this.props.data.forEach((d, k) => {
      if (
        d.EpicKey === epic.Key ||
        (epic.Key === "" &&
          (d.EpicKey === "IMS-55" ||
            d.EpicKey === "IMS-56" ||
            d.EpicKey === "IMS-57" ||
            d.EpicKey === "IMS-58" ||
            d.EpicKey === "IMS-59" ||
            d.EpicKey === "IMS-100"))
      ) {
        invoiceTime += d.InvoiceTime;
        timeSpent += d.TimeSpent;
        remaining += d.Remaining;
      }
    });

    // konverzia na zaokruhlene MDs
    const epicEstimatedMDs = Math.round(epic.Estimated / 36 / 8) / 100;
    const invoiceTimeMDs = Math.round(invoiceTime / 36 / 8) / 100;
    const timeSpentMDs = Math.round(timeSpent / 36 / 8) / 100;
    const remainingMDs = Math.round(remaining / 36 / 8) / 100;

    const tooltip = (
      <>
        Epic estimated: {epicEstimatedMDs}
        <br />
        Invoiced: {invoiceTimeMDs}
        <br />
        Not invoiced: {timeSpentMDs}
        <br />
        Remaining estimation: {remainingMDs}
        <br />
        Not allocated:{" "}
        {epicEstimatedMDs > invoiceTimeMDs + timeSpentMDs + remainingMDs
          ? Math.round(
              (epicEstimatedMDs -
                (invoiceTimeMDs + timeSpentMDs + remainingMDs)) *
                100
            ) / 100
          : "-"}
      </>
    );

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <a href={"https://jira.ims.jsft.io/browse/" + epic.Key}>{epic.Key}</a>
          <span>{epic.Summary}</span>
          <div className="pb">
            <ProgressBar
              sum={epicEstimatedMDs}
              width={"calc(100% * " + epicEstimatedMDs / 500 + ")"}
              fontSize="0.7em"
              tooltip={tooltip}
              bars={[
                {
                  color: "green",
                  value: invoiceTimeMDs,
                  label: invoiceTimeMDs,
                },
                {
                  color: "buttonColour",
                  value: timeSpentMDs,
                  label: timeSpentMDs,
                },
                {
                  color: "mauve",
                  value: remainingMDs,
                  label: remainingMDs,
                },
              ]}
            />
            <span>
              {epicEstimatedMDs < invoiceTimeMDs + timeSpentMDs ? (
                <span style={{ color: "#B10E1E" }}>
                  <ErrorIcon color="inherit" fontSize="inherit" />
                  {Math.round(
                    (epic.Estimated - (invoiceTime + timeSpent)) / 36 / 8
                  ) / 100}
                </span>
              ) : null}
              {epicEstimatedMDs <
              invoiceTimeMDs + timeSpentMDs + remainingMDs ? (
                <span style={{ color: "#FFBF47" }}>
                  <WarningIcon color="inherit" fontSize="inherit" />
                  {Math.round(
                    (epic.Estimated - (invoiceTime + timeSpent + remaining)) /
                      36 /
                      8
                  ) / 100}
                </span>
              ) : null}
            </span>
          </div>
          <div className="labels">
            {epic.Labels?.map((label, key) => {
              return <span key={key}>{label}</span>;
            })}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {rqIssues.map((i, key) => {
            return (
              <Requirement
                key={key}
                issue={i}
                max={largestTimeSum / 3600 / 8}
              />
            );
          })}
          {largeEpicIssues.map((issue, key) => {
            return (
              <LargeIssue
                key={key}
                issue={issue}
                max={largestTimeSum / 3600 / 8}
              />
            );
          })}
          {smallEpicIssues.length > 0 ? (
            <SmallIssues
              issues={smallEpicIssues}
              max={largestTimeSum / 3600 / 8}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
}

class SmallIssues extends React.Component {
  render() {
    const i = this.props.issues;

    let TimeSpent = 0;
    let InvoiceTime = 0;
    let Remaining = 0;
    i.forEach((d) => {
      TimeSpent += d.TimeSpent;
      InvoiceTime += d.InvoiceTime;
      Remaining += d.Remaining;
    });
    const sum = (TimeSpent + InvoiceTime + Remaining) / 3600 / 8;

    const bars = [
      {
        color: "buttonColour",
        value: Math.round((TimeSpent + InvoiceTime) / 36 / 8) / 100,
        label: Math.round((TimeSpent + InvoiceTime) / 36 / 8) / 100,
      },
      {
        color: "mauve",
        value: Math.round(Remaining / 36 / 8) / 100,
        label: Math.round(Remaining / 36 / 8) / 100,
      },
    ];

    const tooltip = (
      <>
        Invoiced: {Math.round(InvoiceTime / 36 / 8) / 100}
        <br />
        Not invoiced: {Math.round(TimeSpent / 36 / 8) / 100}
        <br />
        Remaining: {Math.round(Remaining / 36 / 8) / 100}
        <br />
      </>
    );

    return (
      <div className="smallissues">
        <span>
          {i.map((d, k) => {
            return (
              <span key={k}>
                <img src={issueAvatar[d.Type]} alt={d.Type} />
                <a href={"https://jira.ims.jsft.io/browse/" + d.Key}>{d.Key}</a>
              </span>
            );
          })}
        </span>
        <ProgressBar
          sum={sum}
          width={"calc(50% * " + sum / this.props.max + ")"}
          fontSize="0.7em"
          bars={bars}
          tooltip={tooltip}
        />
      </div>
    );
  }
}

const issueAvatar = {
  requirement:
    "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10515&avatarType=issuetype",
  improvement:
    "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=14101&avatarType=issuetype",
  task: "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10518&avatarType=issuetype",
  service:
    "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=13608&avatarType=issuetype",
  bug: "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=10503&avatarType=issuetype",
  fault:
    "https://jira.ims.jsft.io/secure/viewavatar?size=xsmall&avatarId=12812&avatarType=issuetype",
};

class LargeIssue extends React.Component {
  render() {
    const i = this.props.issue;

    const bars = [
      {
        color: "buttonColour",
        value: Math.round((i.TimeSpent + i.InvoiceTime) / 36 / 8) / 100,
        label: Math.round((i.TimeSpent + i.InvoiceTime) / 36 / 8) / 100,
      },
      {
        color: "mauve",
        value: Math.round(i.Remaining / 36 / 8) / 100,
        label: Math.round(i.Remaining / 36 / 8) / 100,
      },
    ];
    const sum = (i.TimeSpent + i.InvoiceTime + i.Remaining) / 3600 / 8;

    const tooltip = (
      <>
        Invoiced: {Math.round(i.InvoiceTime / 36 / 8) / 100}
        <br />
        Not invoiced: {Math.round(i.TimeSpent / 36 / 8) / 100}
        <br />
        Remaining: {Math.round(i.Remaining / 36 / 8) / 100}
        <br />
      </>
    );

    return (
      <div className="requirement">
        <span>
          <img src={issueAvatar[i.Type]} alt={i.Type} />
          <a href={"https://jira.ims.jsft.io/browse/" + i.Key}>{i.Key}</a>
          <span>{i.Summary}</span>
        </span>
        <Status label={i.Status} />
        <ProgressBar
          sum={sum}
          width={"calc(50% * " + sum / this.props.max + ")"}
          fontSize="0.7em"
          bars={bars}
          tooltip={tooltip}
        />
      </div>
    );
  }
}

class Requirement extends React.Component {
  render() {
    const i = this.props.issue;

    const bars = [
      {
        color: "green",
        value: Math.round((i.TimeSpent + i.InvoiceTime) / 36 / 8) / 100,
        label: Math.round((i.TimeSpent + i.InvoiceTime) / 36 / 8) / 100,
      },
      {
        color: "linkVisitedColour",
        value: Math.round(i.Remaining / 36 / 8) / 100,
        label: Math.round(i.Remaining / 36 / 8) / 100,
      },
      {
        color: "buttonColour",
        value:
          Math.round((i.Features.TimeSpent + i.Features.InvoiceTime) / 36 / 8) /
          100,
        label:
          Math.round((i.Features.TimeSpent + i.Features.InvoiceTime) / 36 / 8) /
          100,
      },
      {
        color: "mauve",
        value: Math.round(i.Features.Remaining / 36 / 8) / 100,
        label: Math.round(i.Features.Remaining / 36 / 8) / 100,
      },
    ];

    const sum =
      (i.TimeSpent +
        i.InvoiceTime +
        i.Remaining +
        i.Features.TimeSpent +
        i.Features.InvoiceTime +
        i.Features.Remaining) /
      3600 /
      8;

    const tooltip = (
      <>
        PA Invoiced: {Math.round(i.InvoiceTime / 36 / 8) / 100}
        <br />
        PA Not invoiced: {Math.round(i.TimeSpent / 36 / 8) / 100}
        <br />
        PA Remaining: {Math.round(i.Remaining / 36 / 8) / 100}
        <br />
        <br />
        Features Invoiced: {Math.round(i.Features.InvoiceTime / 36 / 8) / 100}
        <br />
        Features Not invoiced: {Math.round(i.Features.TimeSpent / 36 / 8) / 100}
        <br />
        Features Remaining: {Math.round(i.Features.Remaining / 36 / 8) / 100}
        <br />
      </>
    );

    return (
      <div className="requirement">
        <span>
          <img src={issueAvatar[i.Type]} alt="requirement" />
          <a href={"https://jira.ims.jsft.io/browse/" + i.Key}>{i.Key}</a>
          <span>{i.Summary}</span>
        </span>
        <Status label={i.Status} />
        <ProgressBar
          sum={sum}
          width={"calc(50% * " + sum / this.props.max + ")"}
          fontSize="0.7em"
          bars={bars}
          tooltip={tooltip}
        />
      </div>
    );
  }
}
