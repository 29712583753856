import React from 'react'


import { Paper } from '@mui/material'

import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Label } from 'recharts'

import { colours } from '../colours'

export default class IssueType extends React.Component {

  addToWeek(item, week, data) {
    const index = data.findIndex((element) => {
      return element.week === week
    })
    if (item.requirementKey) {
      data[index].Requirement = Math.round((data[index].Requirement + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.featureKey) {
      data[index].Feature = Math.round((data[index].Feature + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.improvementKey) {
      data[index].Improvement = Math.round((data[index].Improvement + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.taskKey) {
      data[index].Task = Math.round((data[index].Task + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.bugKey && item.bugReporterRole) {
      data[index].Bug = Math.round((data[index].Bug + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.bugKey && !item.bugReporterRole) {
      data[index].BugClient = Math.round((data[index].BugClient + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.faultKey && item.bugReporterRole) {
      data[index].Fault = Math.round((data[index].Fault + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.faultKey && !item.bugReporterRole) {
      data[index].FaultClient = Math.round((data[index].FaultClient + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }
    if (item.serviceKey) {
      data[index].Service = Math.round((data[index].Service + Math.round((item.timeSpent) / 3600 / 8 * 100) / 100) * 100) / 100
    }

    return data
  }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  prepareData(origData) {
    let data = []

    origData.map((item, key) => {
      if (data.filter(function (d) {
        return d.week === item.week
      }).length === 0) {
        const weekSplit = item.week.split("-")
        const date = this.getDateOfISOWeek(weekSplit[1], weekSplit[0])
        data = [...data, {
          week: item.week,
          weekDate: date.getFullYear() + "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("0" + date.getDate()).slice(-2),
          Requirement: 0,
          Feature: 0,
          Improvement: 0,
          Task: 0,
          Bug: 0,
          BugClient: 0,
          Fault: 0,
          FaultClient: 0,
          Service: 0,
        }]
      }

      data = this.addToWeek(item, item.week, data)
      return null
    })
    return data
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Issue type</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={1000} height={400} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}  >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="weekDate">
              <Label value="weeks (monday date)" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              label={{ value: 'MDs', angle: -90, position: 'insideLeft' }} />
            <Legend verticalAlign="top" />
            <Tooltip formatter={(value, name, props) => { return value + " MDs" }}/>
            <Bar dataKey="Requirement" fill={colours.green} />
            <Bar dataKey="Feature" fill={colours.grey1} />
            <Bar dataKey="Improvement" fill={colours.turquoise} />
            <Bar dataKey="Task" fill={colours.lightBlue} />
            <Bar dataKey="Bug" stackId="bugs" fill={colours.mellowRed} />
            <Bar dataKey="BugClient" stackId="bugs" fill={colours.orange} />
            <Bar dataKey="Fault" stackId="faults" fill={colours.orange} />
            <Bar dataKey="FaultClient" stackId="faults" fill={colours.yellow} />
            <Bar dataKey="Service" fill={colours.pink} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    )
  }
}
