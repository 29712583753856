import React from 'react'

import Tooltip from '@mui/material/Tooltip'

import '../styles/progressBar.sass'

const backgroundColors = {
  textColour: "#0B0C0C",
  secondaryTextColour: "#6F777B",
  pageColour: "#FFFFFF",
  linkColour: "#005EA5",
  linkHoverColour: "#2B8CC4",
  linkVisitedColour: "#4C2C92",
  borderColour: "#BFC1C3",
  panelColour: "#DEE0E2",
  highlightColour: "#F8F8F8",
  buttonColour: "#00823B",
  focusColour: "#FFBF47",
  errorColour: "#B10E1E",
  black: "#0B0C0C",
  grey1: "#6F777B",
  grey2: "#BFC1C3",
  grey3: "#DEE0E2",
  grey4: "#F8F8F8",
  purple: "#2E358B",
  pink: "#D53880",
  mellowRed: "#DF3034",
  yellow: "#FFBF47",
  turquoise: "#28A197",
  mauve: "#6F72AF",
  babyPink: "#F499BE",
  orange: "#F47738",
  green: "#006435",
  lightBlue: "#2B8CC4",
  fuschia: "#912B88",
  red: "#B10E1E",
  brown: "#B58840",
  grassGreen: "#85994B",
  govukBlue: "#005EA5",
}

export default class ProgressBar extends React.Component {
  render() {
    let barCount = 0
    const progressBar = (
      <div className="progressBar" style={{ flexBasis: this.props.width }}>
          {this.props.bars.map((item, i) => {
            if (barCount >= 1) {
              return null
            }
            barCount += item.value / this.props.sum
            return (
               <Bar
                 key={i}
                 backgroundColor={item.color}
                 percentage={item.value/this.props.sum}
                 label={item.label}
                 fontSize={this.props.fontSize}
                 />
             )
          })}
      </div>
    )
    if (this.props.tooltip) {
      return (
        <Tooltip
          title={this.props.tooltip}
          >
          {progressBar}
        </Tooltip>
      )
    } else {
      return progressBar
    }
  }
}

class Bar extends React.Component {
  render() {
    return (
      <div
        className="bar"
        style={{
          backgroundColor: backgroundColors[this.props.backgroundColor],
          flexBasis: (this.props.percentage * 100) + "%",
          height: "100%",
          fontSize: this.props.fontSize
        }}
        >
        <span>{this.props.label}</span>
      </div>
    )
  }
}
