import React from 'react'

import { Paper } from '@mui/material'

import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Bar, Label, Legend } from 'recharts'

import { colours } from '../colours'


export default class VersionGraph extends React.Component {
  prepareData(origData) {
    let data = []

    origData.forEach((item, i) => {
      const bugs = item.bugs||[]
      let reopened = item.reopenedBugs||[]

      let bugsWithoutReopened = bugs.filter((b) => {
        let reopenedIndex = reopened.findIndex((r) => {
          return r.key === b.key
        })
        return (reopenedIndex === -1) && (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
      })


      if (reopened && reopened.length > 1) {
        reopened = reopened.filter((b) => {
          return (b.resolution.id !== "2" && b.resolution.id !== "3" && b.resolution.id !== "5")
        })
      }

      if (item.released === true) {
        data[data.length] = {
          'name': item.name,
          'date': ((item.releaseDate) ? (" " + item.releaseDate) : ""),
          'New bugs': bugsWithoutReopened.length,
          'Reopened bugs': reopened.length,
          'Features fixed': (item.featuresFixed||[]).length,
          'Bugs fixed': (item.bugsFixed||[]).length,
        }
      } else {
        data[data.length] = {
          'name': item.name,
          'date': ((item.releaseDate) ? (" " + item.releaseDate) : ""),
          'New bugs': bugsWithoutReopened.length,
          'Reopened bugs': reopened.length,
          'Features fixed': (item.featuresFixed||[]).length,
          'Bugs fixed': (item.bugsFixed||[]).length,
        }
      }
    })

    return data.reverse()
  }

  render() {
    const data = this.prepareData(this.props.data)
    return(
      <Paper
        className="version">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart width={730} height={250} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}>
            <defs>
              <linearGradient id="colorNew" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colours["orange"]} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colours["orange"]} stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorReopened" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colours["red"]} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colours["red"]} stopOpacity={0}/>
              </linearGradient>
            </defs>
            <Legend verticalAlign="top" />
            <XAxis dataKey="name">
              <Label value="version" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              label={{ value: 'count', angle: -90, position: 'insideLeft' }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip labelFormatter={(value) => {
              const v = this.props.data.filter((d) => {
                return d.name === value
              })
              if (v.length === 1 && (v[0].releaseDate !== null || v[0].releaseDate !== "")) {
                return value + " (" + v[0].releaseDate+ ")" + ((v[0].released) ? "" : " not released")
              }

              return value
            }} />
            <Bar dataKey="Features fixed" stackId="x" barSize={20} fill={colours["grassGreen"]} />
            <Bar dataKey="Bugs fixed" stackId="x" barSize={20} fill={colours["red"]} />
            <Area type="monotone" dataKey="New bugs" stroke={colours["orange"]} fillOpacity={1} fill="url(#colorNew)" />
            <Area type="monotone" dataKey="Reopened bugs" stroke={colours["red"]} fillOpacity={1} fill="url(#colorReopened)" />
          </ComposedChart>
        </ResponsiveContainer>
      </Paper>
    )
  }
}
