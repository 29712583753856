export const colours = {
  textColour: "#0B0C0C",
  secondaryTextColour: "#6F777B",
  pageColour: "#FFFFFF",
  linkColour: "#005EA5",
  linkHoverColour: "#2B8CC4",
  linkVisitedColour: "#4C2C92",
  borderColour: "#BFC1C3",
  panelColour: "#DEE0E2",
  highlightColour: "#F8F8F8",
  buttonColour: "#00823B",
  focusColour: "#FFBF47",
  errorColour: "#B10E1E",
  black: "#0B0C0C",
  grey1: "#6F777B",
  grey2: "#BFC1C3",
  grey3: "#DEE0E2",
  grey4: "#F8F8F8",
  purple: "#2E358B",
  pink: "#D53880",
  mellowRed: "#DF3034",
  yellow: "#FFBF47",
  turquoise: "#28A197",
  mauve: "#6F72AF",
  babyPink: "#F499BE",
  orange: "#F47738",
  green: "#006435",
  lightBlue: "#2B8CC4",
  fuschia: "#912B88",
  red: "#B10E1E",
  brown: "#B58840",
  grassGreen: "#85994B",
  govukBlue: "#005EA5",
}
