import React from 'react'


import { Paper } from '@mui/material'

import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts'

import { colours } from '../colours'

export default class ContractOverall extends React.Component {
  prepareData(origData) {
    let sum = 0

    origData.map((item, key) => {
      sum += item.timeSpent
      return null
    })

    sum = Math.round(sum / 3600 / 8 * 100) / 100

    return [{
      name: "logged",
      value: sum
    },{
      name: "remaining",
      value: (12000 - sum)
    }]
  }
  render() {
    const data = this.prepareData(this.props.data)
    return (
      <Paper className="dashboardPaper">
        <h2>Contract overall</h2>
        <p>Logged: {(data && data.length === 2) ? data[0].value : ""} MDs</p>
        <PieChart width={400} height={400}>
          <Legend />
          <Tooltip formatter={(value, name, props) => { return value + " MDs; " + Math.round((value/12000) * 10000)/100 + " %" }} />
          <Pie data={data} dataKey="value" nameKey="name" fill="#8884d8">
            {
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={(entry.name === "logged") ? colours.grassGreen : colours.grey2}/>
              ))
            }
          </Pie>
        </PieChart>
      </Paper>
    )
  }
}
